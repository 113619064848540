// Copyright (C) 2022 TANNER AG

import React, { createContext, useContext, useEffect, useState } from "react";
import Login from "../views/login/Login";
import { useUserQuery } from "../graphql";

type AuthContextProps = {
    logout(): void;
};

const AuthContext = createContext<AuthContextProps>({ logout: () => null });

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC = ({ children }) => {
    const [authenticated, setAuthenticated] = useState(false);
    const [{ fetching, error }, refetch] = useUserQuery({ requestPolicy: "network-only" });

    const handleSuccessLogin = () => {
        refetch();
    };

    const logout = () => {
        window.localStorage.removeItem("access-token");
        refetch();
    };

    useEffect(() => {
        setAuthenticated(!error && !fetching);
    }, [error, fetching]);

    if (fetching) {
        return <>Loading ...</>;
    }

    return (
        <AuthContext.Provider value={{ logout }}>
            {authenticated ? children : <Login onSuccess={handleSuccessLogin} />}
        </AuthContext.Provider>
    );
};

export default useAuth;
