// Copyright (C) 2022 TANNER AG

import React from "react";
import InputBase, { InputBaseProps } from "@mui/material/InputBase";
import FormControl from "./FormControl";
import { styled } from "@mui/material/styles";

type Props = {
    id: string;
    label?: string;
    helperText?: string;
    required?: boolean;
    placeholder?: string;
    value?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    InputProps?: InputBaseProps;
};

const FormInput: React.FC<Props> = ({ label, id, helperText, required, placeholder, InputProps , onChange, value}) => {
    return (
        <FormControl label={label} required={required} helperText={helperText}>
            <Input required={required} id={id} name={id} placeholder={placeholder} onChange={onChange} value={value} {...InputProps} />
        </FormControl>
    );
};

export const Input = styled(InputBase)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    border: "1px solid",
    borderColor: theme.palette.grey["200"],
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
    transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
    "&.Mui-disabled": {
        backgroundColor: "#f8f8f8",
        borderColor: "#f8f8f8",
        color: theme.palette.text.primary,
        "& > .MuiInputBase-input": {
            WebkitTextFillColor: theme.palette.text.primary
        }
    },
    "&.MuiInputBase-multiline": {
        paddingTop: 0,
        paddingBottom: 0
    },
    "&.MuiInputBase-adornedStart": {
        paddingLeft: 10
    },
    "&.MuiInputBase-adornedEnd": {
        paddingRight: 10
    },
    "& .MuiInputAdornment-positionStart + input": {
        paddingLeft: 0
    },
    "& .MuiInputBase-input": {
        fontSize: 14,
        padding: "10px 12px",
        lineHeight: "20px"
    },
    "&:focus-within": {
        borderColor: theme.palette.primary.main
    }
}));

export default FormInput;
