// Copyright (C) 2022 TANNER AG

import React, { useMemo } from "react";
import { Provider } from "urql";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import createTheme from "./theme";
import { client } from "./lib/urql";
import useStore from "./hooks/usestore";
import AdminView from "./views/admin/AdminView";
import { AuthProvider } from "./hooks/useauth";
import SearchView from "./views/search/SearchView";
import Appbar from "./components/appbar/Appbar";
import Footer from "./components/Footer";
import TenderView from "./views/tender/TenderView";
import DocViewer from "./components/docviewer/DocViewer";
import RadarView from "./views/radar/RadarView";

function App() {
    const themeMode = useStore((state) => state.themeMode);
    const theme = useMemo(() => createTheme(themeMode), [themeMode]);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Provider value={client}>
                <AuthProvider>
                    <BrowserRouter>
                        <Appbar />
                        <Routes>
                            <Route path="/" element={<Navigate to="/radar" replace />} />
                            <Route path="/tenders/*" element={<TenderView />} />
                            <Route path="/radar/*" element={<RadarView />} />
                            <Route path="/search/*" element={<SearchView />} />
                            <Route path="/admin" element={<AdminView />} />
                        </Routes>
                        <Footer />
                        <DocViewer />
                    </BrowserRouter>
                </AuthProvider>
            </Provider>
        </ThemeProvider>
    );
}

export default App;
