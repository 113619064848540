// Copyright (C) 2022 TANNER AG

import React from "react";
import Grid from "@mui/material/Grid";
import PositionFilterInput from "./PositionFilterInput";
import PositionFilterStatus from "./PositionFilterStatus";
import SearchView from "../../../search/SearchView";
import Typography from "@mui/material/Typography";
import useTranslation from "../../../../hooks/usetranslation";
import Stack from "@mui/material/Stack";
import PositionToolbarCat from "./PositionToolbarCat";
import useStore from "../../../../hooks/usestore";
import shallow from "zustand/shallow";
import Button from "@mui/material/Button";
import { CloseIcon } from "../../../../components/icons/MANIcons";
import PositionFilterType from "./PositionFilterType";

type Props = {
    levels: string[];
    types: string[];
    total: number;
};

const PositionToolbar: React.FC<Props> = ({ levels, total, types }) => {
    const { t } = useTranslation();
    const [assignMode, setAssignMode] = useStore((state) => [state.assignMode, state.setAssignMode], shallow);

    return (
        <>
            {assignMode && (
                <Stack
                    direction="row"
                    sx={{
                        mb: 2,
                        alignItems: "center",
                        bgcolor: "info.main",
                        mt: -2,
                        borderBottomLeftRadius: 4,
                        borderBottomRightRadius: 4,
                        px: 3,
                        py: 1.5,
                        color: "common.white"
                    }}
                    spacing={2}
                >
                    <Button
                        onClick={() => setAssignMode(false)}
                        startIcon={<CloseIcon fontSize="small" />}
                        variant="outlined"
                        sx={{ borderColor: "common.white" }}
                    >
                        Schließen
                    </Button>
                    <Typography variant="h5" color="inherit">
                        Zuordnungsmodus
                    </Typography>
                </Stack>
            )}

            <PositionToolbarCat />
            <Stack direction="row" sx={{ mb: 2 }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={4} lg={4}>
                        <PositionFilterInput />
                    </Grid>
                    <Grid item xs={6} md={3} lg={3}>
                        <PositionFilterStatus levels={levels} />
                    </Grid>
                    <Grid item xs={6} md={3} lg={1}>
                        <PositionFilterType types={types} />
                    </Grid>
                    <Grid
                        item
                        xs={0}
                        md={2}
                        lg={4}
                        sx={{ display: { xs: "none", md: "flex", justifyContent: "flex-end", alignItems: "flex-end" } }}
                    >
                        <SearchView />
                    </Grid>
                </Grid>
            </Stack>
            <Typography variant="subtitle2" color="textSecondary" sx={{ mb: 2 }}>
                {t("total_positions", { total: String(total) })}
            </Typography>
        </>
    );
};

export default PositionToolbar;
