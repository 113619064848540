// Copyright (C) 2022 TANNER AG

import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MuiFormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";

type Props = {
    label?: string;
    helperText?: string;
    required?: boolean;
    children?: React.ReactNode;
};

const FormControl: React.FC<Props> = ({ label, helperText, required, children }) => {
    return (
        <MuiFormControl variant="standard" fullWidth margin="none">
            {label && (
                <Label shrink>
                    {label} {required ? "*" : ""}
                </Label>
            )}
            {helperText && <FormHelperText sx={{ mt: 0, mb: 0.5 }}>{helperText}</FormHelperText>}
            {children}
        </MuiFormControl>
    );
};

const Label = styled(InputLabel)(({ theme }) => ({
    transform: "none",
    position: "relative"
}));

export default FormControl;
