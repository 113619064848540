import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSON: any;
};

export type AllowListEntry = {
  __typename?: 'AllowListEntry';
  entry: Scalars['String'];
  lang: Scalars['String'];
};

export type ApplicableDoc = {
  __typename?: 'ApplicableDoc';
  id: Scalars['ID'];
  pdf?: Maybe<Scalars['String']>;
  refid?: Maybe<Scalars['String']>;
  src?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: Scalars['String'];
};

export type Cart = {
  __typename?: 'Cart';
  items: Array<CartItem>;
  name: Scalars['ID'];
};

export type CartItem = {
  __typename?: 'CartItem';
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  partnr: Scalars['String'];
  qty: Scalars['Int'];
};

export type CartItemDataInput = {
  name?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  partnr: Scalars['String'];
  qty: Scalars['Int'];
};

export type CartItemWhereInput = {
  name: Scalars['String'];
};

export type CartWhereInput = {
  name: Scalars['String'];
};

export type CategoryDataInput = {
  name: Scalars['String'];
};

export type Config = {
  __typename?: 'Config';
  blacklist: Scalars['String'];
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  scope?: Maybe<Scalars['Int']>;
  whitelist: Scalars['String'];
};

export type Content = {
  __typename?: 'Content';
  html?: Maybe<Scalars['String']>;
};

export type DocInfo = {
  __typename?: 'DocInfo';
  applicableDocs?: Maybe<Array<ApplicableDoc>>;
  id: Scalars['ID'];
  oidate?: Maybe<Scalars['String']>;
  partinfo?: Maybe<Scalars['String']>;
  rev?: Maybe<Scalars['String']>;
  revdate?: Maybe<Scalars['String']>;
  revno?: Maybe<Scalars['String']>;
  spl?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type DocTocItem = {
  __typename?: 'DocTocItem';
  id: Scalars['ID'];
  nodes?: Maybe<Array<DocTocItem>>;
  target: DocTocTarget;
  title: Scalars['String'];
};

export type DocTocTarget = {
  __typename?: 'DocTocTarget';
  anchorid: Scalars['String'];
  docid: Scalars['String'];
};

export type DocWhereInput = {
  docId: Scalars['String'];
  partId?: InputMaybe<Scalars['String']>;
};

export type Health = {
  __typename?: 'Health';
  health?: Maybe<Scalars['Boolean']>;
  userAgent?: Maybe<Scalars['String']>;
};

export type InfoItem = {
  __typename?: 'InfoItem';
  doctitle?: Maybe<Scalars['String']>;
  effect?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  matches?: Maybe<Array<InfoItemMatch>>;
  pdf?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  target: InfoTarget;
  task?: Maybe<Scalars['String']>;
  taskid?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type InfoItemMatch = {
  __typename?: 'InfoItemMatch';
  text: Scalars['String'];
};

export type InfoTarget = {
  __typename?: 'InfoTarget';
  anchorid?: Maybe<Scalars['String']>;
  docid?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
};

export type Keyword = {
  __typename?: 'Keyword';
  all?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addAllowlist?: Maybe<Scalars['Boolean']>;
  addAllowlistEntry?: Maybe<Scalars['Boolean']>;
  addCartItem?: Maybe<Cart>;
  addCpvItem?: Maybe<Scalars['Boolean']>;
  addOriginItem?: Maybe<Scalars['Boolean']>;
  createCart?: Maybe<Cart>;
  createPositionCategory?: Maybe<PositionCategory>;
  deleteAllowlist?: Maybe<Scalars['Boolean']>;
  deleteAllowlistEntry?: Maybe<Scalars['Boolean']>;
  deleteCart?: Maybe<Scalars['Boolean']>;
  deleteCpvItem?: Maybe<Scalars['Boolean']>;
  deleteOriginItem?: Maybe<Scalars['Boolean']>;
  removeCartItem?: Maybe<Cart>;
  removePositionCategory?: Maybe<PositionCategory>;
  updateCategoryPositions?: Maybe<PositionResult>;
  updateTenders?: Maybe<Scalars['Boolean']>;
};


export type MutationAddAllowlistArgs = {
  lang: Scalars['String'];
};


export type MutationAddAllowlistEntryArgs = {
  entry: Scalars['String'];
  lang: Scalars['String'];
};


export type MutationAddCartItemArgs = {
  data: CartItemDataInput;
  where: CartItemWhereInput;
};


export type MutationAddCpvItemArgs = {
  cpv: Scalars['String'];
  projectType: Scalars['String'];
};


export type MutationAddOriginItemArgs = {
  origin: Scalars['String'];
  projectType: Scalars['String'];
};


export type MutationCreateCartArgs = {
  where: CartWhereInput;
};


export type MutationCreatePositionCategoryArgs = {
  data: CategoryDataInput;
};


export type MutationDeleteAllowlistArgs = {
  lang: Scalars['String'];
};


export type MutationDeleteAllowlistEntryArgs = {
  entry: Scalars['String'];
  lang: Scalars['String'];
};


export type MutationDeleteCartArgs = {
  where: CartWhereInput;
};


export type MutationDeleteCpvItemArgs = {
  cpv: Scalars['String'];
  projectType: Scalars['String'];
};


export type MutationDeleteOriginItemArgs = {
  origin: Scalars['String'];
  projectType: Scalars['String'];
};


export type MutationRemoveCartItemArgs = {
  partNo: Scalars['String'];
  where: CartItemWhereInput;
};


export type MutationRemovePositionCategoryArgs = {
  id: Scalars['String'];
};


export type MutationUpdateCategoryPositionsArgs = {
  id: Scalars['String'];
  mode?: InputMaybe<UpdateMode>;
  positions: Array<Scalars['String']>;
};

export type PaginationInput = {
  limit?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['Int']>;
};

export type Position = {
  __typename?: 'Position';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  keywords?: Maybe<Array<Keyword>>;
  levels?: Maybe<Array<Scalars['String']>>;
  notes?: Maybe<Scalars['String']>;
  pos: Scalars['String'];
  referencedPositions?: Maybe<Array<Position>>;
  references?: Maybe<Array<Reference>>;
  specifications?: Maybe<Scalars['String']>;
  subitems?: Maybe<Array<Position>>;
  type?: Maybe<Scalars['String']>;
};

export type PositionCategory = {
  __typename?: 'PositionCategory';
  custom?: Maybe<Scalars['Boolean']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  positions?: Maybe<Array<Scalars['String']>>;
};

export type PositionFilter = {
  __typename?: 'PositionFilter';
  levels: Array<Scalars['String']>;
  types: Array<Scalars['String']>;
};

export type PositionResult = {
  __typename?: 'PositionResult';
  filter: PositionFilter;
  items: Array<Position>;
  total: Scalars['Int'];
};

export type PositionWhereInput = {
  assignMode?: InputMaybe<Scalars['Boolean']>;
  cat?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  level?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type ProjectParameters = {
  __typename?: 'ProjectParameters';
  cpv?: Maybe<Array<Scalars['String']>>;
  origin?: Maybe<Array<Scalars['String']>>;
  projectType: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  allowlistEntries?: Maybe<Array<AllowListEntry>>;
  cart?: Maybe<Cart>;
  carts: Array<Scalars['String']>;
  config?: Maybe<Config>;
  docInfo?: Maybe<DocInfo>;
  docToc: Array<DocTocItem>;
  getAllowlists?: Maybe<Array<Maybe<Scalars['String']>>>;
  health?: Maybe<Health>;
  position?: Maybe<Position>;
  positionCategories: Array<PositionCategory>;
  positions?: Maybe<PositionResult>;
  queryParameters?: Maybe<Array<ProjectParameters>>;
  search?: Maybe<SearchResult>;
  searchFilter: Array<Scalars['String']>;
  tender?: Maybe<Tender>;
  tenders: Array<Tender>;
  tendersCount?: Maybe<TenderCount>;
  user?: Maybe<User>;
};


export type QueryAllowlistEntriesArgs = {
  lang?: InputMaybe<Scalars['String']>;
};


export type QueryCartArgs = {
  where: CartWhereInput;
};


export type QueryDocInfoArgs = {
  where: DocWhereInput;
};


export type QueryDocTocArgs = {
  where: DocWhereInput;
};


export type QueryPositionArgs = {
  id: Scalars['String'];
  posId: Scalars['String'];
};


export type QueryPositionCategoriesArgs = {
  id: Scalars['String'];
};


export type QueryPositionsArgs = {
  pagination?: InputMaybe<PaginationInput>;
  where: PositionWhereInput;
};


export type QueryQueryParametersArgs = {
  projectType?: InputMaybe<Scalars['String']>;
};


export type QuerySearchArgs = {
  pagination?: InputMaybe<PaginationInput>;
  where: SearchWhereInput;
};


export type QuerySearchFilterArgs = {
  field: Scalars['String'];
  where: SearchWhereInput;
};


export type QueryTenderArgs = {
  id: Scalars['String'];
};


export type QueryTendersCountArgs = {
  where: TenderCountInput;
};

export type Reference = {
  __typename?: 'Reference';
  id: Scalars['String'];
  name: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type SearchResult = {
  __typename?: 'SearchResult';
  items: Array<InfoItem>;
  total: Scalars['Int'];
};

export type SearchWhereInput = {
  doc?: InputMaybe<Array<Scalars['String']>>;
  effect?: InputMaybe<Array<Scalars['String']>>;
  query?: InputMaybe<Scalars['String']>;
  task?: InputMaybe<Array<Scalars['String']>>;
};

export type Tender = {
  __typename?: 'Tender';
  buyer?: Maybe<TenderBuyer>;
  contract?: Maybe<TenderContract>;
  country: Scalars['String'];
  counts?: Maybe<Array<Scalars['String']>>;
  cpu: Array<Scalars['String']>;
  cpv: Array<Scalars['String']>;
  di: Scalars['String'];
  drivingConcept: Array<Scalars['String']>;
  dt: Scalars['String'];
  formAttrName: Scalars['String'];
  generalUrl: Scalars['String'];
  id: Scalars['ID'];
  lang: Array<Scalars['String']>;
  lang2: Array<Scalars['String']>;
  lefti?: Maybe<TenderLefti>;
  length?: Maybe<Array<Scalars['String']>>;
  nuts: Array<Scalars['String']>;
  nutsContent: Scalars['String'];
  nutsCurrency: Scalars['String'];
  nutsType: Scalars['String'];
  ojs: Scalars['String'];
  performanceNuts: Scalars['String'];
  products?: Maybe<Array<Scalars['String']>>;
  publishDate: Scalars['String'];
  ref?: Maybe<TenderRef>;
  services?: Maybe<Array<Scalars['String']>>;
  shortDesc: Array<Scalars['String']>;
  shortDescOriginal: Array<Scalars['String']>;
  specificUrl: Scalars['String'];
  title: Scalars['String'];
  titleOriginal: Scalars['String'];
  url: Scalars['String'];
};

export type TenderBuyer = {
  __typename?: 'TenderBuyer';
  mail: Scalars['String'];
  name: Scalars['String'];
  nuts: Scalars['String'];
};

export type TenderContract = {
  __typename?: 'TenderContract';
  object_descr?: Maybe<Array<Maybe<TenderContractDesc>>>;
  short_descr?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
};

export type TenderContractDesc = {
  __typename?: 'TenderContractDesc';
  counts?: Maybe<Array<Scalars['String']>>;
  drivingConcept: Array<Scalars['String']>;
  item?: Maybe<Scalars['String']>;
  length?: Maybe<Array<Scalars['String']>>;
  products?: Maybe<Array<Scalars['String']>>;
  short_descr?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
};

export type TenderCount = {
  __typename?: 'TenderCount';
  count: Scalars['Int'];
};

export type TenderCountInput = {
  blacklist: Scalars['String'];
  categories?: InputMaybe<Scalars['String']>;
  scope?: InputMaybe<Scalars['String']>;
  whitelist: Scalars['String'];
};

export type TenderLefti = {
  __typename?: 'TenderLefti';
  economic_financial_info?: Maybe<Array<Maybe<Scalars['String']>>>;
  performance_conditions?: Maybe<Array<Maybe<Scalars['String']>>>;
  qualification?: Maybe<Array<Maybe<TenderQualification>>>;
  suitability?: Maybe<Array<Maybe<Scalars['String']>>>;
  technical_professional_info?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TenderQualification = {
  __typename?: 'TenderQualification';
  conditions?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TenderRef = {
  __typename?: 'TenderRef';
  id: Scalars['ID'];
  ojs: Scalars['String'];
};

export enum UpdateMode {
  Remove = 'remove',
  Update = 'update'
}

export type User = {
  __typename?: 'User';
  name?: Maybe<Scalars['String']>;
  username: Scalars['String'];
};

export type AddAllowlistMutationVariables = Exact<{
  lang: Scalars['String'];
}>;


export type AddAllowlistMutation = { __typename?: 'Mutation', addAllowlist?: boolean | undefined };

export type AddAllowlistEntryMutationVariables = Exact<{
  lang: Scalars['String'];
  entry: Scalars['String'];
}>;


export type AddAllowlistEntryMutation = { __typename?: 'Mutation', addAllowlistEntry?: boolean | undefined };

export type AddCartItemMutationVariables = Exact<{
  where: CartItemWhereInput;
  data: CartItemDataInput;
}>;


export type AddCartItemMutation = { __typename?: 'Mutation', addCartItem?: { __typename?: 'Cart', name: string, items: Array<{ __typename?: 'CartItem', partnr: string, qty: number, name?: string | undefined, note?: string | undefined }> } | undefined };

export type AddCpvItemMutationVariables = Exact<{
  projectType: Scalars['String'];
  cpv: Scalars['String'];
}>;


export type AddCpvItemMutation = { __typename?: 'Mutation', addCpvItem?: boolean | undefined };

export type AddOriginItemMutationVariables = Exact<{
  projectType: Scalars['String'];
  origin: Scalars['String'];
}>;


export type AddOriginItemMutation = { __typename?: 'Mutation', addOriginItem?: boolean | undefined };

export type CreateCartMutationVariables = Exact<{
  where: CartWhereInput;
}>;


export type CreateCartMutation = { __typename?: 'Mutation', createCart?: { __typename?: 'Cart', name: string, items: Array<{ __typename?: 'CartItem', partnr: string, qty: number, name?: string | undefined, note?: string | undefined }> } | undefined };

export type CreateCategoryMutationVariables = Exact<{
  data: CategoryDataInput;
}>;


export type CreateCategoryMutation = { __typename?: 'Mutation', createPositionCategory?: { __typename?: 'PositionCategory', id: string, name: string, icon?: string | undefined, custom?: boolean | undefined } | undefined };

export type DeleteAllowlistMutationVariables = Exact<{
  lang: Scalars['String'];
}>;


export type DeleteAllowlistMutation = { __typename?: 'Mutation', deleteAllowlist?: boolean | undefined };

export type DeleteAllowlistEntryMutationVariables = Exact<{
  lang: Scalars['String'];
  entry: Scalars['String'];
}>;


export type DeleteAllowlistEntryMutation = { __typename?: 'Mutation', deleteAllowlistEntry?: boolean | undefined };

export type DeleteCpvItemMutationVariables = Exact<{
  projectType: Scalars['String'];
  cpv: Scalars['String'];
}>;


export type DeleteCpvItemMutation = { __typename?: 'Mutation', deleteCpvItem?: boolean | undefined };

export type DeleteOriginItemMutationVariables = Exact<{
  projectType: Scalars['String'];
  origin: Scalars['String'];
}>;


export type DeleteOriginItemMutation = { __typename?: 'Mutation', deleteOriginItem?: boolean | undefined };

export type RemoveCartItemMutationVariables = Exact<{
  where: CartItemWhereInput;
  partNo: Scalars['String'];
}>;


export type RemoveCartItemMutation = { __typename?: 'Mutation', removeCartItem?: { __typename?: 'Cart', name: string, items: Array<{ __typename?: 'CartItem', partnr: string, qty: number, name?: string | undefined, note?: string | undefined }> } | undefined };

export type RemoveCategoryMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveCategoryMutation = { __typename?: 'Mutation', removePositionCategory?: { __typename?: 'PositionCategory', id: string, name: string, icon?: string | undefined, custom?: boolean | undefined } | undefined };

export type UpdateCategoryPositionsMutationVariables = Exact<{
  id: Scalars['String'];
  positions: Array<Scalars['String']> | Scalars['String'];
  mode?: InputMaybe<UpdateMode>;
}>;


export type UpdateCategoryPositionsMutation = { __typename?: 'Mutation', updateCategoryPositions?: { __typename?: 'PositionResult', total: number, items: Array<{ __typename?: 'Position', id: string }> } | undefined };

export type UpdateTendersMutationVariables = Exact<{ [key: string]: never; }>;


export type UpdateTendersMutation = { __typename?: 'Mutation', updateTenders?: boolean | undefined };

export type AllowlistEntriesQueryVariables = Exact<{
  lang?: InputMaybe<Scalars['String']>;
}>;


export type AllowlistEntriesQuery = { __typename?: 'Query', allowlistEntries?: Array<{ __typename?: 'AllowListEntry', lang: string, entry: string }> | undefined };

export type CartQueryVariables = Exact<{
  where: CartWhereInput;
}>;


export type CartQuery = { __typename?: 'Query', cart?: { __typename?: 'Cart', name: string, items: Array<{ __typename?: 'CartItem', name?: string | undefined, qty: number, partnr: string, note?: string | undefined }> } | undefined };

export type CartsQueryVariables = Exact<{ [key: string]: never; }>;


export type CartsQuery = { __typename?: 'Query', carts: Array<string> };

export type PositionCategoriesQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type PositionCategoriesQuery = { __typename?: 'Query', positionCategories: Array<{ __typename?: 'PositionCategory', id: string, name: string, icon?: string | undefined, custom?: boolean | undefined, positions?: Array<string> | undefined }> };

export type ConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type ConfigQuery = { __typename?: 'Query', config?: { __typename?: 'Config', categories?: Array<string | undefined> | undefined, blacklist: string, whitelist: string, scope?: number | undefined } | undefined };

export type DocInfoQueryVariables = Exact<{
  where: DocWhereInput;
}>;


export type DocInfoQuery = { __typename?: 'Query', docInfo?: { __typename?: 'DocInfo', id: string, title: string, type?: string | undefined, rev?: string | undefined, revdate?: string | undefined, revno?: string | undefined, spl?: string | undefined, version?: string | undefined, applicableDocs?: Array<{ __typename?: 'ApplicableDoc', id: string, title: string, type: string, status?: string | undefined, refid?: string | undefined, src?: string | undefined }> | undefined } | undefined };

export type DocTocQueryVariables = Exact<{
  where: DocWhereInput;
}>;


export type DocTocQuery = { __typename?: 'Query', docToc: Array<{ __typename?: 'DocTocItem', id: string, title: string, target: { __typename?: 'DocTocTarget', docid: string, anchorid: string }, nodes?: Array<{ __typename?: 'DocTocItem', id: string, title: string, target: { __typename?: 'DocTocTarget', docid: string, anchorid: string }, nodes?: Array<{ __typename?: 'DocTocItem', id: string, title: string, target: { __typename?: 'DocTocTarget', docid: string, anchorid: string }, nodes?: Array<{ __typename?: 'DocTocItem', id: string, title: string, target: { __typename?: 'DocTocTarget', docid: string, anchorid: string } }> | undefined }> | undefined }> | undefined }> };

export type GetAllowlistsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllowlistsQuery = { __typename?: 'Query', getAllowlists?: Array<string | undefined> | undefined };

export type HealthQueryVariables = Exact<{ [key: string]: never; }>;


export type HealthQuery = { __typename?: 'Query', health?: { __typename?: 'Health', health?: boolean | undefined, userAgent?: string | undefined } | undefined };

export type PositionQueryVariables = Exact<{
  id: Scalars['String'];
  posId: Scalars['String'];
}>;


export type PositionQuery = { __typename?: 'Query', position?: { __typename?: 'Position', id: string, description?: string | undefined, type?: string | undefined, notes?: string | undefined, pos: string, levels?: Array<string> | undefined, specifications?: string | undefined, references?: Array<{ __typename?: 'Reference', id: string, name: string, type?: string | undefined }> | undefined, subitems?: Array<{ __typename?: 'Position', id: string, description?: string | undefined, type?: string | undefined, notes?: string | undefined, specifications?: string | undefined, references?: Array<{ __typename?: 'Reference', id: string, name: string, type?: string | undefined }> | undefined, keywords?: Array<{ __typename?: 'Keyword', key?: string | undefined, text?: string | undefined }> | undefined }> | undefined, keywords?: Array<{ __typename?: 'Keyword', key?: string | undefined, text?: string | undefined }> | undefined, referencedPositions?: Array<{ __typename?: 'Position', id: string, description?: string | undefined, type?: string | undefined, notes?: string | undefined, pos: string, levels?: Array<string> | undefined, specifications?: string | undefined }> | undefined } | undefined };

export type PositionsQueryVariables = Exact<{
  where: PositionWhereInput;
  pagination: PaginationInput;
}>;


export type PositionsQuery = { __typename?: 'Query', positions?: { __typename?: 'PositionResult', total: number, filter: { __typename?: 'PositionFilter', levels: Array<string>, types: Array<string> }, items: Array<{ __typename?: 'Position', id: string, description?: string | undefined, type?: string | undefined, notes?: string | undefined, pos: string, levels?: Array<string> | undefined, specifications?: string | undefined, references?: Array<{ __typename?: 'Reference', id: string, name: string, type?: string | undefined }> | undefined, subitems?: Array<{ __typename?: 'Position', id: string, description?: string | undefined, type?: string | undefined, notes?: string | undefined, specifications?: string | undefined, references?: Array<{ __typename?: 'Reference', id: string, name: string, type?: string | undefined }> | undefined, keywords?: Array<{ __typename?: 'Keyword', key?: string | undefined, text?: string | undefined }> | undefined }> | undefined, keywords?: Array<{ __typename?: 'Keyword', key?: string | undefined, text?: string | undefined }> | undefined }> } | undefined };

export type QueryParametersQueryVariables = Exact<{
  projectType?: InputMaybe<Scalars['String']>;
}>;


export type QueryParametersQuery = { __typename?: 'Query', queryParameters?: Array<{ __typename?: 'ProjectParameters', projectType: string, cpv?: Array<string> | undefined, origin?: Array<string> | undefined }> | undefined };

export type SearchQueryVariables = Exact<{
  where: SearchWhereInput;
  pagination: PaginationInput;
}>;


export type SearchQuery = { __typename?: 'Query', search?: { __typename?: 'SearchResult', total: number, items: Array<{ __typename?: 'InfoItem', id: string, title: string, subtitle?: string | undefined, type?: string | undefined, score?: string | undefined, effect?: Array<string> | undefined, task?: string | undefined, taskid?: string | undefined, doctitle?: string | undefined, pdf?: string | undefined, target: { __typename?: 'InfoTarget', docid?: string | undefined, anchorid?: string | undefined, link?: string | undefined }, matches?: Array<{ __typename?: 'InfoItemMatch', text: string }> | undefined }> } | undefined };

export type SearchFilterQueryVariables = Exact<{
  where: SearchWhereInput;
  field: Scalars['String'];
}>;


export type SearchFilterQuery = { __typename?: 'Query', searchFilter: Array<string> };

export type TenderQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type TenderQuery = { __typename?: 'Query', tender?: { __typename?: 'Tender', id: string, url: string, country: string, lang: Array<string>, generalUrl: string, specificUrl: string, cpu: Array<string>, nuts: Array<string>, nutsContent: string, nutsType: string, nutsCurrency: string, lang2: Array<string>, formAttrName: string, titleOriginal: string, shortDescOriginal: Array<string>, title: string, shortDesc: Array<string>, drivingConcept: Array<string>, publishDate: string, di: string, cpv: Array<string>, dt: string, ojs: string, products?: Array<string> | undefined, length?: Array<string> | undefined, counts?: Array<string> | undefined, performanceNuts: string, services?: Array<string> | undefined, ref?: { __typename?: 'TenderRef', id: string, ojs: string } | undefined, buyer?: { __typename?: 'TenderBuyer', name: string, mail: string, nuts: string } | undefined, contract?: { __typename?: 'TenderContract', title?: string | undefined, short_descr?: Array<string> | undefined, object_descr?: Array<{ __typename?: 'TenderContractDesc', item?: string | undefined, title?: string | undefined, short_descr?: Array<string | undefined> | undefined, counts?: Array<string> | undefined, length?: Array<string> | undefined, products?: Array<string> | undefined, drivingConcept: Array<string> } | undefined> | undefined } | undefined, lefti?: { __typename?: 'TenderLefti', economic_financial_info?: Array<string | undefined> | undefined, performance_conditions?: Array<string | undefined> | undefined, technical_professional_info?: Array<string | undefined> | undefined, suitability?: Array<string | undefined> | undefined, qualification?: Array<{ __typename?: 'TenderQualification', conditions?: Array<string | undefined> | undefined } | undefined> | undefined } | undefined } | undefined };

export type TendersQueryVariables = Exact<{ [key: string]: never; }>;


export type TendersQuery = { __typename?: 'Query', tenders: Array<{ __typename?: 'Tender', id: string, url: string, country: string, lang: Array<string>, generalUrl: string, specificUrl: string, cpu: Array<string>, nuts: Array<string>, nutsContent: string, nutsType: string, nutsCurrency: string, lang2: Array<string>, formAttrName: string, titleOriginal: string, shortDescOriginal: Array<string>, title: string, shortDesc: Array<string>, drivingConcept: Array<string>, publishDate: string, di: string, cpv: Array<string>, dt: string, ojs: string, products?: Array<string> | undefined, length?: Array<string> | undefined, counts?: Array<string> | undefined, performanceNuts: string, ref?: { __typename?: 'TenderRef', id: string, ojs: string } | undefined, buyer?: { __typename?: 'TenderBuyer', name: string, mail: string, nuts: string } | undefined, contract?: { __typename?: 'TenderContract', title?: string | undefined, short_descr?: Array<string> | undefined, object_descr?: Array<{ __typename?: 'TenderContractDesc', item?: string | undefined, title?: string | undefined, short_descr?: Array<string | undefined> | undefined } | undefined> | undefined } | undefined }> };

export type TenderCountQueryVariables = Exact<{
  categories?: InputMaybe<Scalars['String']>;
  blacklist: Scalars['String'];
  whitelist: Scalars['String'];
  scope?: InputMaybe<Scalars['String']>;
}>;


export type TenderCountQuery = { __typename?: 'Query', tendersCount?: { __typename?: 'TenderCount', count: number } | undefined };

export type UserQueryVariables = Exact<{ [key: string]: never; }>;


export type UserQuery = { __typename?: 'Query', user?: { __typename?: 'User', username: string, name?: string | undefined } | undefined };


export const AddAllowlistDocument = gql`
    mutation AddAllowlist($lang: String!) {
  addAllowlist(lang: $lang)
}
    `;

export function useAddAllowlistMutation() {
  return Urql.useMutation<AddAllowlistMutation, AddAllowlistMutationVariables>(AddAllowlistDocument);
};
export const AddAllowlistEntryDocument = gql`
    mutation AddAllowlistEntry($lang: String!, $entry: String!) {
  addAllowlistEntry(lang: $lang, entry: $entry)
}
    `;

export function useAddAllowlistEntryMutation() {
  return Urql.useMutation<AddAllowlistEntryMutation, AddAllowlistEntryMutationVariables>(AddAllowlistEntryDocument);
};
export const AddCartItemDocument = gql`
    mutation AddCartItem($where: CartItemWhereInput!, $data: CartItemDataInput!) {
  addCartItem(where: $where, data: $data) {
    name
    items {
      partnr
      qty
      name
      note
    }
  }
}
    `;

export function useAddCartItemMutation() {
  return Urql.useMutation<AddCartItemMutation, AddCartItemMutationVariables>(AddCartItemDocument);
};
export const AddCpvItemDocument = gql`
    mutation AddCpvItem($projectType: String!, $cpv: String!) {
  addCpvItem(projectType: $projectType, cpv: $cpv)
}
    `;

export function useAddCpvItemMutation() {
  return Urql.useMutation<AddCpvItemMutation, AddCpvItemMutationVariables>(AddCpvItemDocument);
};
export const AddOriginItemDocument = gql`
    mutation AddOriginItem($projectType: String!, $origin: String!) {
  addOriginItem(projectType: $projectType, origin: $origin)
}
    `;

export function useAddOriginItemMutation() {
  return Urql.useMutation<AddOriginItemMutation, AddOriginItemMutationVariables>(AddOriginItemDocument);
};
export const CreateCartDocument = gql`
    mutation CreateCart($where: CartWhereInput!) {
  createCart(where: $where) {
    name
    items {
      partnr
      qty
      name
      note
    }
  }
}
    `;

export function useCreateCartMutation() {
  return Urql.useMutation<CreateCartMutation, CreateCartMutationVariables>(CreateCartDocument);
};
export const CreateCategoryDocument = gql`
    mutation CreateCategory($data: CategoryDataInput!) {
  createPositionCategory(data: $data) {
    id
    name
    icon
    custom
  }
}
    `;

export function useCreateCategoryMutation() {
  return Urql.useMutation<CreateCategoryMutation, CreateCategoryMutationVariables>(CreateCategoryDocument);
};
export const DeleteAllowlistDocument = gql`
    mutation DeleteAllowlist($lang: String!) {
  deleteAllowlist(lang: $lang)
}
    `;

export function useDeleteAllowlistMutation() {
  return Urql.useMutation<DeleteAllowlistMutation, DeleteAllowlistMutationVariables>(DeleteAllowlistDocument);
};
export const DeleteAllowlistEntryDocument = gql`
    mutation DeleteAllowlistEntry($lang: String!, $entry: String!) {
  deleteAllowlistEntry(lang: $lang, entry: $entry)
}
    `;

export function useDeleteAllowlistEntryMutation() {
  return Urql.useMutation<DeleteAllowlistEntryMutation, DeleteAllowlistEntryMutationVariables>(DeleteAllowlistEntryDocument);
};
export const DeleteCpvItemDocument = gql`
    mutation DeleteCpvItem($projectType: String!, $cpv: String!) {
  deleteCpvItem(projectType: $projectType, cpv: $cpv)
}
    `;

export function useDeleteCpvItemMutation() {
  return Urql.useMutation<DeleteCpvItemMutation, DeleteCpvItemMutationVariables>(DeleteCpvItemDocument);
};
export const DeleteOriginItemDocument = gql`
    mutation DeleteOriginItem($projectType: String!, $origin: String!) {
  deleteOriginItem(projectType: $projectType, origin: $origin)
}
    `;

export function useDeleteOriginItemMutation() {
  return Urql.useMutation<DeleteOriginItemMutation, DeleteOriginItemMutationVariables>(DeleteOriginItemDocument);
};
export const RemoveCartItemDocument = gql`
    mutation RemoveCartItem($where: CartItemWhereInput!, $partNo: String!) {
  removeCartItem(where: $where, partNo: $partNo) {
    name
    items {
      partnr
      qty
      name
      note
    }
  }
}
    `;

export function useRemoveCartItemMutation() {
  return Urql.useMutation<RemoveCartItemMutation, RemoveCartItemMutationVariables>(RemoveCartItemDocument);
};
export const RemoveCategoryDocument = gql`
    mutation RemoveCategory($id: String!) {
  removePositionCategory(id: $id) {
    id
    name
    icon
    custom
  }
}
    `;

export function useRemoveCategoryMutation() {
  return Urql.useMutation<RemoveCategoryMutation, RemoveCategoryMutationVariables>(RemoveCategoryDocument);
};
export const UpdateCategoryPositionsDocument = gql`
    mutation UpdateCategoryPositions($id: String!, $positions: [String!]!, $mode: UpdateMode) {
  updateCategoryPositions(id: $id, positions: $positions, mode: $mode) {
    total
    items {
      id
    }
  }
}
    `;

export function useUpdateCategoryPositionsMutation() {
  return Urql.useMutation<UpdateCategoryPositionsMutation, UpdateCategoryPositionsMutationVariables>(UpdateCategoryPositionsDocument);
};
export const UpdateTendersDocument = gql`
    mutation UpdateTenders {
  updateTenders
}
    `;

export function useUpdateTendersMutation() {
  return Urql.useMutation<UpdateTendersMutation, UpdateTendersMutationVariables>(UpdateTendersDocument);
};
export const AllowlistEntriesDocument = gql`
    query AllowlistEntries($lang: String) {
  allowlistEntries(lang: $lang) {
    lang
    entry
  }
}
    `;

export function useAllowlistEntriesQuery(options?: Omit<Urql.UseQueryArgs<AllowlistEntriesQueryVariables>, 'query'>) {
  return Urql.useQuery<AllowlistEntriesQuery, AllowlistEntriesQueryVariables>({ query: AllowlistEntriesDocument, ...options });
};
export const CartDocument = gql`
    query Cart($where: CartWhereInput!) {
  cart(where: $where) {
    name
    items {
      name
      qty
      partnr
      note
    }
  }
}
    `;

export function useCartQuery(options: Omit<Urql.UseQueryArgs<CartQueryVariables>, 'query'>) {
  return Urql.useQuery<CartQuery, CartQueryVariables>({ query: CartDocument, ...options });
};
export const CartsDocument = gql`
    query Carts {
  carts
}
    `;

export function useCartsQuery(options?: Omit<Urql.UseQueryArgs<CartsQueryVariables>, 'query'>) {
  return Urql.useQuery<CartsQuery, CartsQueryVariables>({ query: CartsDocument, ...options });
};
export const PositionCategoriesDocument = gql`
    query PositionCategories($id: String!) {
  positionCategories(id: $id) {
    id
    name
    icon
    custom
    positions
  }
}
    `;

export function usePositionCategoriesQuery(options: Omit<Urql.UseQueryArgs<PositionCategoriesQueryVariables>, 'query'>) {
  return Urql.useQuery<PositionCategoriesQuery, PositionCategoriesQueryVariables>({ query: PositionCategoriesDocument, ...options });
};
export const ConfigDocument = gql`
    query Config {
  config {
    categories
    blacklist
    whitelist
    scope
  }
}
    `;

export function useConfigQuery(options?: Omit<Urql.UseQueryArgs<ConfigQueryVariables>, 'query'>) {
  return Urql.useQuery<ConfigQuery, ConfigQueryVariables>({ query: ConfigDocument, ...options });
};
export const DocInfoDocument = gql`
    query DocInfo($where: DocWhereInput!) {
  docInfo(where: $where) {
    id
    title
    type
    rev
    revdate
    revno
    spl
    version
    applicableDocs {
      id
      title
      type
      status
      refid
      src
    }
  }
}
    `;

export function useDocInfoQuery(options: Omit<Urql.UseQueryArgs<DocInfoQueryVariables>, 'query'>) {
  return Urql.useQuery<DocInfoQuery, DocInfoQueryVariables>({ query: DocInfoDocument, ...options });
};
export const DocTocDocument = gql`
    query DocToc($where: DocWhereInput!) {
  docToc(where: $where) {
    id
    title
    target {
      docid
      anchorid
    }
    nodes {
      id
      title
      target {
        docid
        anchorid
      }
      nodes {
        id
        title
        target {
          docid
          anchorid
        }
        nodes {
          id
          title
          target {
            docid
            anchorid
          }
        }
      }
    }
  }
}
    `;

export function useDocTocQuery(options: Omit<Urql.UseQueryArgs<DocTocQueryVariables>, 'query'>) {
  return Urql.useQuery<DocTocQuery, DocTocQueryVariables>({ query: DocTocDocument, ...options });
};
export const GetAllowlistsDocument = gql`
    query GetAllowlists {
  getAllowlists
}
    `;

export function useGetAllowlistsQuery(options?: Omit<Urql.UseQueryArgs<GetAllowlistsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAllowlistsQuery, GetAllowlistsQueryVariables>({ query: GetAllowlistsDocument, ...options });
};
export const HealthDocument = gql`
    query Health {
  health {
    health
    userAgent
  }
}
    `;

export function useHealthQuery(options?: Omit<Urql.UseQueryArgs<HealthQueryVariables>, 'query'>) {
  return Urql.useQuery<HealthQuery, HealthQueryVariables>({ query: HealthDocument, ...options });
};
export const PositionDocument = gql`
    query Position($id: String!, $posId: String!) {
  position(id: $id, posId: $posId) {
    id
    description
    type
    notes
    pos
    levels
    specifications
    references {
      id
      name
      type
    }
    subitems {
      id
      description
      type
      notes
      specifications
      references {
        id
        name
        type
      }
      keywords {
        key
        text
      }
    }
    keywords {
      key
      text
    }
    referencedPositions {
      id
      description
      type
      notes
      pos
      levels
      specifications
    }
  }
}
    `;

export function usePositionQuery(options: Omit<Urql.UseQueryArgs<PositionQueryVariables>, 'query'>) {
  return Urql.useQuery<PositionQuery, PositionQueryVariables>({ query: PositionDocument, ...options });
};
export const PositionsDocument = gql`
    query Positions($where: PositionWhereInput!, $pagination: PaginationInput!) {
  positions(where: $where, pagination: $pagination) {
    total
    filter {
      levels
      types
    }
    items {
      id
      description
      type
      notes
      pos
      levels
      specifications
      references {
        id
        name
        type
      }
      subitems {
        id
        description
        type
        notes
        specifications
        references {
          id
          name
          type
        }
        keywords {
          key
          text
        }
      }
      keywords {
        key
        text
      }
    }
  }
}
    `;

export function usePositionsQuery(options: Omit<Urql.UseQueryArgs<PositionsQueryVariables>, 'query'>) {
  return Urql.useQuery<PositionsQuery, PositionsQueryVariables>({ query: PositionsDocument, ...options });
};
export const QueryParametersDocument = gql`
    query QueryParameters($projectType: String) {
  queryParameters(projectType: $projectType) {
    projectType
    cpv
    origin
  }
}
    `;

export function useQueryParametersQuery(options?: Omit<Urql.UseQueryArgs<QueryParametersQueryVariables>, 'query'>) {
  return Urql.useQuery<QueryParametersQuery, QueryParametersQueryVariables>({ query: QueryParametersDocument, ...options });
};
export const SearchDocument = gql`
    query Search($where: SearchWhereInput!, $pagination: PaginationInput!) {
  search(where: $where, pagination: $pagination) {
    total
    items {
      id
      title
      subtitle
      type
      score
      effect
      task
      taskid
      doctitle
      pdf
      target {
        docid
        anchorid
        link
      }
      matches {
        text
      }
    }
  }
}
    `;

export function useSearchQuery(options: Omit<Urql.UseQueryArgs<SearchQueryVariables>, 'query'>) {
  return Urql.useQuery<SearchQuery, SearchQueryVariables>({ query: SearchDocument, ...options });
};
export const SearchFilterDocument = gql`
    query SearchFilter($where: SearchWhereInput!, $field: String!) {
  searchFilter(where: $where, field: $field)
}
    `;

export function useSearchFilterQuery(options: Omit<Urql.UseQueryArgs<SearchFilterQueryVariables>, 'query'>) {
  return Urql.useQuery<SearchFilterQuery, SearchFilterQueryVariables>({ query: SearchFilterDocument, ...options });
};
export const TenderDocument = gql`
    query Tender($id: String!) {
  tender(id: $id) {
    id
    url
    country
    lang
    generalUrl
    specificUrl
    ref {
      id
      ojs
    }
    cpu
    nuts
    nutsContent
    nutsType
    nutsCurrency
    lang2
    formAttrName
    titleOriginal
    shortDescOriginal
    title
    shortDesc
    drivingConcept
    publishDate
    di
    cpv
    dt
    ojs
    buyer {
      name
      mail
      nuts
    }
    products
    length
    counts
    performanceNuts
    services
    contract {
      title
      short_descr
      object_descr {
        item
        title
        short_descr
        counts
        length
        products
        drivingConcept
      }
    }
    lefti {
      economic_financial_info
      performance_conditions
      technical_professional_info
      suitability
      qualification {
        conditions
      }
    }
  }
}
    `;

export function useTenderQuery(options: Omit<Urql.UseQueryArgs<TenderQueryVariables>, 'query'>) {
  return Urql.useQuery<TenderQuery, TenderQueryVariables>({ query: TenderDocument, ...options });
};
export const TendersDocument = gql`
    query Tenders {
  tenders {
    id
    url
    country
    lang
    generalUrl
    specificUrl
    ref {
      id
      ojs
    }
    cpu
    nuts
    nutsContent
    nutsType
    nutsCurrency
    lang2
    formAttrName
    titleOriginal
    shortDescOriginal
    title
    shortDesc
    drivingConcept
    publishDate
    di
    cpv
    dt
    ojs
    buyer {
      name
      mail
      nuts
    }
    products
    length
    counts
    performanceNuts
    contract {
      title
      short_descr
      object_descr {
        item
        title
        short_descr
      }
    }
  }
}
    `;

export function useTendersQuery(options?: Omit<Urql.UseQueryArgs<TendersQueryVariables>, 'query'>) {
  return Urql.useQuery<TendersQuery, TendersQueryVariables>({ query: TendersDocument, ...options });
};
export const TenderCountDocument = gql`
    query TenderCount($categories: String, $blacklist: String!, $whitelist: String!, $scope: String) {
  tendersCount(
    where: {categories: $categories, blacklist: $blacklist, whitelist: $whitelist, scope: $scope}
  ) {
    count
  }
}
    `;

export function useTenderCountQuery(options: Omit<Urql.UseQueryArgs<TenderCountQueryVariables>, 'query'>) {
  return Urql.useQuery<TenderCountQuery, TenderCountQueryVariables>({ query: TenderCountDocument, ...options });
};
export const UserDocument = gql`
    query User {
  user {
    username
    name
  }
}
    `;

export function useUserQuery(options?: Omit<Urql.UseQueryArgs<UserQueryVariables>, 'query'>) {
  return Urql.useQuery<UserQuery, UserQueryVariables>({ query: UserDocument, ...options });
};