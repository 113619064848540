// Copyright (C) 2020 TANNER AG

import React, { MouseEvent } from "react";
import Link from "@mui/material/Link";
import useParam from "../../hooks/useparam";

type Props = {
    href?: string;
};

const ContentLink: React.FC<Props> = ({ href, children, ...others }) => {
    const [, setPos] = useParam("pos");

    const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        const href = event.currentTarget.href;

        try {
            if (href) {
                window.open(href, "_blank")?.focus();
                return;
            }

            const id = event.currentTarget.getAttribute("data-id") ?? "";
            const type = event.currentTarget.getAttribute("data-type") ?? "";

            if (type === "position" && id) {
                setPos(id);
                return;
            }
        } catch (err) {
            console.log(err);
        }
    };
    return (
        <Link href={href} onClick={handleClick} {...others}>
            {children}
        </Link>
    );
};

export default ContentLink;
