import React from "react";
import {Grid} from "@mui/material";
import {styled} from "@mui/material/styles";
import {LoadingButton} from "@mui/lab";
import FormSelect from "../../../components/inputs/FormSelect";
import FormInput from "../../../components/inputs/FormInput";
import {PlusIcon} from "../../../components/icons/MANIcons";
import {languageArrayType} from "./radarConfig.types";

const StyledButton = styled(LoadingButton)(({theme}) => ({
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
        backgroundColor: theme.palette.secondary.dark
    }
}));

type RadarConfigNewItemProps = {
    languages: languageArrayType;
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
};

const RadarConfigNewItem: React.FC<RadarConfigNewItemProps> = ({languages, onSubmit}) => (
    <form onSubmit={onSubmit}>
        <Grid container direction="row" marginTop={1} marginBottom={3} spacing={2}>
            <Grid item xs={2}>
                <FormSelect label="" id="lang" options={languages} />
            </Grid>
            <Grid item xs={8}>
                <FormInput
                    id={`entry`}
                    InputProps={{
                        name: "entry",
                        className: "white-list"
                    }}
                />
            </Grid>
            <Grid item xs={2} display="flex" justifyContent="end">
                <StyledButton type="submit" startIcon={<PlusIcon />} fullWidth>
                    Begrieff hinzufugen
                </StyledButton>
            </Grid>
        </Grid>
    </form>
);

export default RadarConfigNewItem;
