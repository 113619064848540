// Copyright (C) 2022 TANNER AG

import React from "react";
import Skeleton from "@mui/material/Skeleton";
import ResultListItemWrapper from "./ResultListItemWrapper";
import Stack from "@mui/material/Stack";

type Props = {
    loading?: boolean;
};

const ResultListLoading: React.FC<Props> = ({ loading }) => {
    if (!loading) {
        return null;
    }

    return (
        <>
            {Array.from(Array(6).keys()).map((key) => (
                <ResultListItemWrapper
                    key={key}
                    actions={
                        <>
                            <Skeleton width="100%" height={40} variant="rectangular" />
                            <Skeleton width="100%" height={40} variant="rectangular" />
                        </>
                    }
                >
                    <Skeleton width="60%" />
                    {Array.from(Array(4).keys()).map((key) => (
                        <Skeleton key={key} height={16} width="85%" />
                    ))}
                    <Stack direction="row" sx={{ mt: 1, width: "85%" }} spacing={2}>
                        {Array.from(Array(3).keys()).map((key) => (
                            <Skeleton key={key} width="100%" height={20} variant="rectangular" />
                        ))}
                    </Stack>
                </ResultListItemWrapper>
            ))}
        </>
    );
};

export default ResultListLoading;
