// Copyright (C) 2022 TANNER AG

import React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { ChatIcon, DataIcon } from "../../../../../components/icons/MANIcons";

const PositionDetailsActions: React.FC = () => {
    return (
        <Stack direction="row" spacing={1} sx={{ px: 3, pt: 2, borderTop: "1px solid", borderColor: "grey.100" }}>
            <Button startIcon={<ChatIcon color="secondary" />}>Kommentar hinzufügen</Button>
            <Button startIcon={<DataIcon color="secondary" />}>Kategorie zuordnen</Button>
        </Stack>
    );
};

export default PositionDetailsActions;
