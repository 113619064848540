// Copyright (C) 2022 TANNER AG

import React from "react";
import useParam from "../../../../hooks/useparam";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import useStore from "../../../../hooks/usestore";
import shallow from "zustand/shallow";

type Props = {};

const PositionListNoResult: React.FC<Props> = () => {
    const [activeCat] = useParam("cat");
    const setAssignMode = useStore((state) => state.setAssignMode, shallow);

    if (!activeCat) {
        return <Typography align="center">Keine Ergebnisse</Typography>;
    }

    return (
        <Stack>
            <Typography align="center">
                Der ausgewählten Kategorie wurden noch keine Lasten zugeordnet. Zuordnung{" "}
                <Link onClick={() => setAssignMode(true)}>jetzt starten</Link>.
            </Typography>
        </Stack>
    );
};

export default PositionListNoResult;
