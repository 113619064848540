// Copyright (C) 2022 TANNER AG

import React from "react";
import Button from "@mui/material/Button";
import { useNavigate, To } from "react-router-dom";
import useTranslation from "../hooks/usetranslation";
import { ArrowLeftIcon } from "./icons/MANIcons";

type Props = {
    to: To;
};

const BackButton: React.FC<Props> = ({ to }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <Button
            startIcon={<ArrowLeftIcon color="secondary" />}
            onClick={() => navigate(to)}
            sx={{ mb: 1.5, mt: 1, ml: -2 }}
        >
            {t("back_to_overview")}
        </Button>
    );
};

export default BackButton;
