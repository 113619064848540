// Copyright (C) 2022 TANNER AG

import React from "react";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import MuiDialog, { DialogProps } from "@mui/material/Dialog";
import useBreakpoint from "../../hooks/usebreakpoint";

type Props = DialogProps & {
    onClose?(): void;
};

const Dialog: React.FC<Props> = ({ children, onClose, ...dialogProps }) => {
    const { down } = useBreakpoint("sm");
    return (
        <MuiDialog
            fullWidth
            maxWidth="sm"
            onClose={onClose}
            fullScreen={down}
            TransitionComponent={Transition}
            {...dialogProps}
        >
            {children}
        </MuiDialog>
    );
};

export const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} timeout={400} />;
});

export default Dialog;
