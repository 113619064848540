// Copyright (C) 2022 TANNER AG

import React from "react";
import FormSelect from "../../../../components/inputs/FormSelect";
import { useSearchParams } from "react-router-dom";

type Props = {
    levels: string[];
};

const PositionFilterStatus: React.FC<Props> = ({ levels }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const value = searchParams.get("level") ?? "";

    const handleChange = (value: string) => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.delete("page");
        newSearchParams.set("level", value);
        setSearchParams(newSearchParams);
    };

    return (
        <FormSelect
            id="status"
            label="Kategorie"
            options={[{ label: "Alle", value: "" }, ...levels.map((level) => ({ label: level, value: level }))]}
            SelectProps={{ value: value || "", onChange: (event) => handleChange(event.currentTarget.value) }}
        />
    );
};

export default PositionFilterStatus;
