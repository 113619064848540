import React from "react";
import {styled} from "@mui/material/styles";
import {AutocompleteProps} from "@mui/material/Autocomplete";
import {Autocomplete, TextField} from "@mui/material";
import {CountryOptionType} from "./radarConfig.types";
import {countryList} from "./constants";

type AutoCompleteOriginProps = {
    selectedOrigin: CountryOptionType[];
    onSelectedOrigin: (value: CountryOptionType[]) => void;
};

export default function AutoCompleteOrigin(props: AutoCompleteOriginProps) {
    const {selectedOrigin, onSelectedOrigin} = props;

    const StyledAutoComplete = styled(Autocomplete)<AutocompleteProps<CountryOptionType, boolean, boolean, any>>(
        ({theme}) => `
        & {
            label {
                transform: none;
                position: relative;
                font-size: 20px;
                margin-bottom: 20px;
            }
            legend {
                display: none;
            }
            fieldset,
             fieldset: hover{
                border: 1px solid #abb6c3 !important;
            }
            
            &:hover fieldset {
                border: 1px solid #abb6c3;
                }
        }
    `
    );

    return (
        <>
            <StyledAutoComplete
                id="origin"
                options={countryList}
                multiple
                value={selectedOrigin}
                getOptionLabel={(option: any) => `${option.alpha2}`}
                onChange={(event, value: unknown) => onSelectedOrigin(value as any[])}
                renderInput={(params: any) => (
                    <TextField
                        {...params}
                        label="Länder"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: <>{params.InputProps.endAdornment}</>
                        }}
                    />
                )}
            />
        </>
    );
}
