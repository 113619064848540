// Copyright (C) 2022 TANNER AG

import React from "react";
import FormControl from "./FormControl";
import NativeSelect, { NativeSelectProps } from "@mui/material/NativeSelect";
import { Input } from "./FormInput";
import { ChevronDownIcon } from "../icons/MANIcons";
import { styled } from "@mui/material/styles";

type Props = {
    id: string;
    label: string;
    helperText?: string;
    required?: boolean;
    SelectProps?: NativeSelectProps;
    options: { value: string; label: string }[];
};

const FormSelect: React.FC<Props> = ({ id, label, helperText, options, required, SelectProps }) => {
    return (
        <FormControl label={label} required={required} helperText={helperText}>
            <Select
                id={id}
                name={id}
                required={required}
                input={<Input />}
                IconComponent={ChevronDownIcon}
                {...SelectProps}
            >
                {options.map(({ label, value }) => (
                    <option key={value} value={value}>
                        {label}
                    </option>
                ))}
            </Select>
        </FormControl>
    );
};

const Select = styled(NativeSelect)(({ theme }) => ({
    "& 	.MuiSelect-icon, & .MuiNativeSelect-icon": {
        fontSize: 16,
        marginRight: 8
    }
}));

export default FormSelect;
