// Copyright (C) 2022 TANNER AG

export type TrackReqParams = {
    /**
     * <strong>action</strong>
     * This is the specific action that is taken. For example, with the Video category, you might have a Play, Pause and Complete action.
     */
    a?: string;
    /**
     * <strong>category</strong>
     * This describes the type of events you want to track. For example, Link Clicks, Videos, Outbound Links, and Form Events.
     */
    c?: string;
    /**
     * <strong>name</strong>
     * This is usually the title of the element that is being interacted with, to aid with analysis. For example, it could be the name of a Video that was played or the specific form that is being submitted.
     */
    n?: string;
    /**
     * <strong>value1</strong>
     * This is a numeric value and is often added dynamically. It could be the cost of a product that is added to a cart, or the completion percentage of a video.
     */
    v1?: string;
    /**
     * <strong>value2</strong>
     * This is a numeric value and is often added dynamically. It could be the cost of a product that is added to a cart, or the completion percentage of a video.
     */
    v2?: string;
    /**
     * <strong>url</strong>
     * This is the associated url where the action is executed
     */
    url?: string;
    /**
     * <strong>userAgent</strong>
     * Browser User-Agent from request
     */
    ua?: string;
    /**
     * <strong>resolution</strong>
     * Screen resolution from client device
     */
    res?: string;
    /**
     * <strong>session id</strong>
     * Identifier of current user session
     */
    sid?: string;
};

// const ANALYTICS_API_URL = `${process.env.REACT_APP_SERVICE_URL ?? ""}/api/t`;

const generateID = () => "_" + Math.random().toString(36).substring(2, 11);

const getSessionID = () => {
    const sessionID = window.sessionStorage.getItem("sessionId");
    if (!sessionID) {
        window.sessionStorage.setItem("sessionId", generateID());
    }

    return window.sessionStorage.getItem("sessionId") || "";
};

/**
 * Get screen resolution as string like 1920x1080
 * To detect the native resolution of a mobile device display (retina) - multiply screen width/height with device pixel ratio
 */
const getResolution = () =>
    `${window.screen.width * window.devicePixelRatio}x${window.screen.height * window.devicePixelRatio}`;

const useAnalytics = () => {
    const send = (params: Omit<TrackReqParams, "ua" | "res" | "sid" | "url">) => {
        try {
            const reqParams: TrackReqParams = {
                res: getResolution(),
                sid: getSessionID(),
                url: window.location.href,
                ...params
            };
            console.debug(reqParams);
            // window.navigator?.sendBeacon(ANALYTICS_API_URL, new URLSearchParams(reqParams));
        } catch (err) {
            console.log(err);
        }
    };

    return { a: send };
};

export default useAnalytics;
