// Copyright (C) 2022 TANNER AG

import React from "react";
import Drawer from "@mui/material/Drawer";
import useStore from "../../hooks/usestore";
import shallow from "zustand/shallow";
import PdfViewer from "../PdfViewer";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import { scrollbarSmall } from "../../lib/utils";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { CloseIcon, OpenIcon } from "../icons/MANIcons";
import IconButton from "@mui/material/IconButton";

const DocViewer: React.FC = () => {
    const [doc, setDoc] = useStore((state) => [state.doc, state.setDoc], shallow);
    const handleClose = () => setDoc();

    return (
        <Drawer
            open={Boolean(doc)}
            onClose={handleClose}
            variant="temporary"
            anchor="right"
            sx={{ zIndex: 1300, ".MuiDrawer-paper": { maxWidth: 920, width: "90%" } }}
        >
            <Stack sx={{ height: 1 }}>
                <Toolbar>
                    <Stack>
                        <Typography variant="h5">{doc?.title}</Typography>
                        <Typography variant="body2">{doc?.subTitle}</Typography>
                    </Stack>
                    <Stack sx={{ ml: "auto", alignItems: "center" }} direction="row">
                        <Button
                            startIcon={<OpenIcon />}
                            variant="contained"
                            color="secondary"
                            component="a"
                            href={doc?.src}
                            target="_blank"
                        >
                            Open
                        </Button>
                        <IconButton edge="end" sx={{ ml: 2 }} onClick={handleClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </Stack>
                </Toolbar>
                <Stack sx={{ flexGrow: 1, overflow: "auto", ...scrollbarSmall }}>
                    <PdfViewer src={doc?.src} />
                </Stack>
            </Stack>
        </Drawer>
    );
};

export default DocViewer;
