// Copyright (C) 2022 TANNER AG

import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import BackButton from "../../../components/BackButton";
import { useTenderQuery } from "../../../graphql";
import CategoryList from "./categories/CategoryList";
import useParam from "../../../hooks/useparam";
import TenderTabBar from "./TenderTabBar";

type Props = {
    id: string;
};

const TenderSidebar: React.FC<Props> = ({ id }) => {
    const [{ data }] = useTenderQuery({ variables: { id } });
    const [posId] = useParam("pos");
    const [index, setIndex] = useState(0);

    return (
        <StyledDrawer variant="persistent" width={450} anchor="left" open={Boolean(!posId)}>
            <Stack direction="row">
                <BackButton to="/tenders" />
            </Stack>
            <Stack sx={{ mb: 4 }}>
                <Typography variant="h4">{data?.tender?.title}</Typography>
                <Typography variant="h5" color="textSecondary">
                    {data?.tender?.id} • {data?.tender?.lang?.join(", ")}
                </Typography>
            </Stack>
            <TenderTabBar index={index} setIndex={setIndex} />
            <CategoryList showCustom={index === 1} />
        </StyledDrawer>
    );
};

// const closedMixin = (theme: Theme): CSSObject => ({
//     transition: theme.transitions.create("width", {
//         easing: theme.transitions.easing.sharp,
//         duration: theme.transitions.duration.leavingScreen
//     }),
//     overflowX: "hidden",
//     width: `calc(${theme.spacing(7)} + 1px)`,
//     [theme.breakpoints.up("sm")]: {
//         width: `calc(${theme.spacing(8)} + 1px)`
//     }
// });

const StyledDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== "width" })<{
    width: number;
}>(({ theme, width, open }) => ({
    flexShrink: 0,
    width: open ? width : 0,
    zIndex: 1301,
    [theme.breakpoints.up("md")]: {
        zIndex: 1099
    },
    "& .MuiDrawer-paper": {
        width,
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(2),
        overflowY: "visible",
        borderRight: "none",
        zIndex: 1099,
        backgroundColor: theme.palette.background.paper,

        [theme.breakpoints.up("md")]: {
            paddingTop: theme.spacing(10),
            paddingBottom: 16,
            paddingRight: theme.spacing(3),
            paddingLeft: theme.spacing(3)
        }
    }
    // ...(!open && {
    //     ...closedMixin(theme),
    //     "& .MuiDrawer-paper": closedMixin(theme)
    // })
}));

export default TenderSidebar;
