// Copyright (C) 2022 TANNER AG

import React from "react";
import Typography from "@mui/material/Typography";
import useTranslation from "../../../hooks/usetranslation";
import Stack from "@mui/material/Stack";
import MainWrapper from "../../../components/MainWrapper";
import Pagination from "../details/positions/Pagination";
import useTenders from "../hooks/usetenders";
import ErrorAlert from "../../../components/error/ErrorAlert";

const TenderOverview: React.FC = () => {
    const { t } = useTranslation();
    const { total, error } = useTenders();

    return (
        <MainWrapper>
            <Stack direction="row" sx={{ alignItems: "center", mb: 4 }}>
                <Typography variant="h2">TED Radar</Typography>
            </Stack>
            {error && <ErrorAlert title={error.message} />}
            <Typography variant="subtitle2" color="textSecondary" sx={{ mb: 2 }}>
                {t("total_tenders", { total })}
            </Typography>
            <Pagination total={total} />
        </MainWrapper>
    );
};

export default TenderOverview;
