// Copyright (C) 2022 TANNER AG

import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { boldFontFamily } from "../theme";
import { SxProps } from "@mui/system";

type Props = {
    label: string;
    value?: React.ReactNode;
    minWidth?: number | string;
    sx?: SxProps;
    wrapperSx?: SxProps;
    alignItems?: string;
};

const GroupAttr: React.FC<Props> = ({ label, value = "", alignItems = "center", minWidth = 180, sx }) => {
    return (
        <Stack spacing={1} direction="row" sx={{ alignItems }}>
            <Typography
                component="strong"
                variant="body2"
                sx={{ minWidth, fontFamily: boldFontFamily, ...sx }}
                color="textSecondary"
            >
                {label}
            </Typography>
            {typeof value === "string" ? (
                <Typography variant="body2" color="textSecondary">
                    {value}
                </Typography>
            ) : (
                value
            )}
        </Stack>
    );
};

export default GroupAttr;
