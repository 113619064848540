// Copyright (C) 2022 TANNER AG

import React, { useMemo } from "react";
import HeroIcons from "../../../../components/icons/HeroIcons";
import Stack from "@mui/material/Stack";
import { ArrowRightIcon, CloseIcon, DeleteIcon } from "../../../../components/icons/MANIcons";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { boldFontFamily } from "../../../../theme";
import { useRemoveCategoryMutation } from "../../../../graphql";
import useParam from "../../../../hooks/useparam";
import clsx from "clsx";
import Badge from "@mui/material/Badge";

type Props = {
    id: string;
    icon?: string;
    name: string;
    total: number;
    custom?: boolean;
};

const CategoryListItem: React.FC<Props> = ({ id, name, icon, custom, total }) => {
    const [, removeCategory] = useRemoveCategoryMutation();
    const [activeCat, setActiveCat] = useParam("cat");
    const isActive = useMemo(() => {
        return activeCat === id;
    }, [activeCat, id]);

    const handleRemove = async () => {
        await removeCategory({ id });
    };

    const handleFilter = () => setActiveCat(id, true);
    const handleClear = () => setActiveCat(undefined, true);

    return (
        <Stack
            direction="row"
            component={Paper}
            className={clsx({ selected: isActive })}
            sx={{
                alignItems: { xs: "flex-star", sm: "center" },
                py: 0,
                px: 0,
                borderBottom: "1px solid",
                borderColor: "grey.100",
                borderRadius: 0,
                "&.selected": {
                    bgcolor: "grey.100"
                }
            }}
        >
            <Box
                sx={{
                    width: { xs: 32, sm: 56 },
                    height: { xs: "auto", sm: 56 },
                    display: "flex",
                    alignItems: { xs: "flex-star", sm: "center" },
                    justifyContent: "center",
                    mr: { xs: 1.5, sm: 2 },
                    mt: { xs: 0.75, sm: 0 }
                }}
            >
                <Badge
                    badgeContent={total}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    sx={{
                        ".MuiBadge-badge": {
                            backgroundColor: "common.white",
                            fontFamily: boldFontFamily,
                            fontSize: 12,
                            p: 0,
                            top: 6,
                            color: "secondary.main"
                        }
                    }}
                >
                    <HeroIcons iconName={icon ?? ""} sx={{ fontSize: { xs: 34, sm: 40 } }} />
                </Badge>
            </Box>
            <Stack sx={{ flexGrow: 1, flexDirection: { xs: "column", md: "row", alignItems: "center" } }}>
                <Stack spacing={0.25}>
                    <Typography noWrap variant="body1" sx={{ fontFamily: boldFontFamily }}>
                        {name}
                    </Typography>
                </Stack>
                <Stack spacing={1} direction="row" sx={{ ml: "auto", alignItems: "center" }}>
                    {custom && (
                        <IconButton onClick={handleRemove} color="primary" edge="end">
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    )}
                    {isActive ? (
                        <Box sx={{ width: 36 }}>
                            <IconButton onClick={handleClear} color="primary">
                                <CloseIcon sx={{ fontSize: 12 }} />
                            </IconButton>
                        </Box>
                    ) : (
                        <IconButton onClick={handleFilter} color="secondary">
                            <ArrowRightIcon fontSize="small" />
                        </IconButton>
                    )}
                </Stack>
            </Stack>
        </Stack>
    );
};

export default CategoryListItem;
