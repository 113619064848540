// Copyright (C) 2022 TANNER AG

// @ts-nocheck

import React from "react";
import { useHref } from "react-router-dom";
import useDocStore from "../../store/usedocstore";

type Props = {
    name: string;
    attrs: { [p: string]: string };
};

const Heading: React.FC<Props> = ({ name, attrs, children }) => {
    const docId = useDocStore((state) => state.doc?.id);
    const href = useHref({ pathname: `/documents/${docId}`, hash: attrs.id });
    const Component = name;

    const handleClick = () => {
        if (!window.navigator.share) {
            return;
        }

        const url = `${window.location.origin}${window.location.pathname}${href}`;

        window.navigator.share({ title: docId, url });
    };

    return (
        <Component {...attrs}>
            {children}
            <svg onClick={handleClick} className="share" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
            </svg>
        </Component>
    );
};

export default Heading;
