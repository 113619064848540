// Copyright (C) 2022 TANNER AG

import React from "react";
import SearchInput from "./SearchInput";
import Typography from "@mui/material/Typography";
import FilterActive from "./FilterActive";

export const FILTER = {
    doc: {
        field: "doctitle",
        label: "Document"
    },
    effect: {
        field: "effect",
        label: "Product"
    },
    task: {
        field: "task",
        label: "Chapter"
    }
};

type Props = {
    total: number;
};

const Filter: React.FC<Props> = ({ total }) => {
    return (
        <>
            <SearchInput />
            <FilterActive />
            <Typography variant="subtitle2" color="textSecondary" sx={{ mb: 2 }}>
                {total} result(s)
            </Typography>
        </>
    );
};

export default Filter;
