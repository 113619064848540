// Copyright (C) 2021 TANNER AG

import React from "react";
import parse, { domToReact, HTMLReactParserOptions, Element, attributesToProps } from "html-react-parser";
import Image from "../components/htmlcontent/Image";
import ContentLink from "../components/htmlcontent/ContentLink";
import Heading from "../components/htmlcontent/Heading";
import GalleyLink from "../components/htmlcontent/GalleyLink";
import { runtimeConfig } from "../utils";

const useHTMLParser = (sourceUrl = "") => {
    const parseHTML = (html: string | null | undefined): string | JSX.Element | JSX.Element[] => {
        if (!html) {
            return "";
        }

        const options: HTMLReactParserOptions = {
            trim: true,
            replace: (domNode) => {
                if (domNode.type === "tag") {
                    const element = domNode as Element;
                    const src = element?.attribs?.src ?? "";
                    if (element.name === "img" && element?.attribs?.src) {
                        return (
                            <Image
                                {...element?.attribs}
                                src={`${runtimeConfig.basePath}/${src.substring(src.lastIndexOf("/") + 1)}`}
                            />
                        );
                    }

                    if (element.name === "a") {
                        return (
                            <ContentLink {...attributesToProps(element?.attribs)}>
                                {domToReact(element.children, options)}
                            </ContentLink>
                        );
                    }

                    if (["h1", "h2", "h3"].includes(element.name) && element?.attribs?.id) {
                        return (
                            <Heading name={element.name} attrs={element?.attribs}>
                                {domToReact(element.children, options)}
                            </Heading>
                        );
                    }

                    if (element.name === "div" && element?.attribs?.class?.includes("productentry")) {
                        return (
                            <GalleyLink {...attributesToProps(element?.attribs)}>
                                {domToReact(element.children, options)}
                            </GalleyLink>
                        );
                    }

                    if (
                        [
                            "product",
                            "count",
                            "seats",
                            "length",
                            "drive-e",
                            "drive-verbrenner",
                            "drive-gas",
                            "services"
                        ].includes(element.name ?? "")
                    ) {
                        return <mark title={element.name}>{domToReact(element.children, options)}</mark>;
                    }

                    return false;
                }
                return false;
            }
        };

        return parse(html, options);
    };
    return { parseHTML };
};

export default useHTMLParser;
