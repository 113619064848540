// Copyright (C) 2022 TANNER AG

import { useSearchQuery } from "../../../graphql";
import { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import useAnalytics from "../../../hooks/useanalytics";
import useStore from "../../../hooks/usestore";

const RESULTS_PER_PAGE = 10;

const useSearch = () => {
    const { a } = useAnalytics();
    const params = useStore((state) => state.search);

    const [searchParams] = useSearchParams();
    const query = params?.query ?? "";
    const page = params?.page ?? "1";

    const [start, limit] = useMemo(() => {
        return [(Number(page) - 1) * RESULTS_PER_PAGE, RESULTS_PER_PAGE];
    }, [page]);

    const [{ data, fetching, error }] = useSearchQuery({
        variables: { where: { query }, pagination: { start, limit } }
    });

    useEffect(() => {
        if (data?.search) {
            a({ c: "search", a: "execute", v1: searchParams.toString(), v2: String(data?.search?.total) });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.search]);

    return {
        total: data?.search?.total ?? 0,
        items: data?.search?.items ?? [],
        fetching,
        error
    };
};

export default useSearch;
