// Copyright (C) 2022 TANNER AG

import React from "react";
import Popover, { PopoverOrigin } from "@mui/material/Popover";
import clsx from "clsx";

type Props = {
    anchorEl: null | HTMLElement;
    transformOrigin?: PopoverOrigin;
    anchorOrigin?: PopoverOrigin;
    maxWidth?: number | string;
    backdrop?: boolean;
    disablePortal?: boolean;
    onClose(): void;
    children?: React.ReactNode;
};

const MenuPopover: React.FC<Props> = ({
    anchorEl,
    maxWidth = 280,
    transformOrigin,
    anchorOrigin,
    onClose,
    backdrop,
    disablePortal,
    children
}) => {
    return (
        <Popover
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={onClose}
            className={clsx({ backdrop })}
            sx={{ "&.backdrop > .MuiBackdrop-root": { bgcolor: "rgba(0, 0, 0, 0.1)" } }}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: "visible",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.24)",
                    maxWidth,
                    width: "100%"
                }
            }}
            transformOrigin={{ horizontal: "right", vertical: "top", ...transformOrigin }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom", ...anchorOrigin }}
            disablePortal={disablePortal}
        >
            {children}
        </Popover>
    );
};

export default MenuPopover;
