// Copyright (C) 2022 TANNER AG

import React from "react";
import useParam from "../../../../../hooks/useparam";
import DialogHead from "../../../../../components/dialog/DialogHead";
import { usePositionQuery } from "../../../../../graphql";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { ArrowRightIcon, ChevronRightIcon } from "../../../../../components/icons/MANIcons";
import Stack from "@mui/material/Stack";
import { ListItemWrapper } from "../../../../../components/listitem/ListItemRegular";
import { styled } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import useHTMLParser from "../../../../../hooks/usehtmlparser";
import Chip from "@mui/material/Chip";
import PositionDetailsGroup from "./PositionDetailsGroup";
import ListItemDownload from "../../../../../components/listitem/ListItemDownload";
import Button from "@mui/material/Button";
import useTranslation from "../../../../../hooks/usetranslation";
import useStore from "../../../../../hooks/usestore";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { scrollbarSmall } from "../../../../../utils";
import HtmlContent from "../../../../../components/htmlcontent/HtmlContent";
import PositionDetailsActions from "./PositionDetailsActions";

type Props = {
    enableProjectUpdate?: boolean;
};

const PositionDetails: React.FC<Props> = () => {
    const [posId, setPosId] = useParam("pos");
    const [{ data }] = usePositionQuery({ variables: { id: "", posId }, pause: !posId });
    const { parseHTML } = useHTMLParser();
    const { t } = useTranslation();
    const setDoc = useStore((state) => state.setDoc);

    const handleClose = () => setPosId();

    const referencesDocuments = data?.position?.references?.filter((reference) => reference.type === "document") ?? [];

    return (
        <StyledDrawer open={Boolean(posId)} onClose={handleClose} variant="persistent" anchor="right" width={760}>
            <Box sx={{ pt: 2, px: 3 }}>
                <DialogHead title={data?.position?.pos ?? ""} onClose={handleClose} />
            </Box>
            <Stack sx={{ flexGrow: 1, overflow: "auto", ...scrollbarSmall, px: 3 }}>
                <Breadcrumbs
                    separator={<ChevronRightIcon sx={{ fontSize: 10 }} />}
                    sx={{ mb: 2, "& .MuiBreadcrumbs-separator": { mx: 1, mt: 0.25 } }}
                >
                    {data?.position?.levels?.map((level, key) => (
                        <Typography key={key} variant="h4" sx={{ lineHeight: "28px", color: "secondary.main" }}>
                            {level}
                        </Typography>
                    ))}
                </Breadcrumbs>
                <Stack spacing={0.5} direction="row" sx={{ pb: 2 }}>
                    {data?.position?.keywords?.map((keyword, index) => (
                        <Chip
                            key={`${index}-${keyword}`}
                            size="small"
                            label={keyword.text}
                            color="primary"
                            variant="outlined"
                            sx={{ ".MuiChip-label": { textTransform: "capitalize" } }}
                        />
                    ))}
                </Stack>
                <PositionDetailsGroup>
                    <HtmlContent html={data?.position?.description} />
                    {Boolean(data?.position?.subitems?.length) && (
                        <List disablePadding>
                            {data?.position?.subitems?.map((item) => (
                                <ListItem
                                    key={item.id}
                                    sx={{
                                        borderLeft: "4px solid",
                                        borderColor: "secondary.main",
                                        my: 1.5,
                                        p: 1,
                                        py: 0,
                                        fontSize: 14
                                    }}
                                >
                                    {parseHTML(item?.description)}
                                </ListItem>
                            ))}
                        </List>
                    )}
                </PositionDetailsGroup>
                {Boolean(data?.position?.specifications) && (
                    <PositionDetailsGroup label="Vorgaben">
                        <HtmlContent html={data?.position?.specifications} />
                    </PositionDetailsGroup>
                )}
                {Boolean(data?.position?.referencedPositions?.length) && (
                    <PositionDetailsGroup label="Referenzierte Lasten">
                        {data?.position?.referencedPositions?.map((item) => (
                            <ListItemWrapper key={item.id} border>
                                <Stack sx={{ p: 1 }}>
                                    <Typography variant="h5">{item.pos}</Typography>
                                    <Typography variant="body2" sx={{ pr: 2 }} component="div" className="html-content">
                                        {parseHTML(item?.description)}
                                    </Typography>
                                    <Typography variant="body2" sx={{ pr: 2 }} component="div" className="html-content">
                                        {parseHTML(item?.specifications)}
                                    </Typography>
                                </Stack>
                            </ListItemWrapper>
                        ))}
                    </PositionDetailsGroup>
                )}
                {Boolean(referencesDocuments.length) && (
                    <PositionDetailsGroup label="Referenzierte Dokumente">
                        <Stack>
                            {referencesDocuments.map((reference) => (
                                <ListItemDownload
                                    id={reference.id}
                                    name={reference.name}
                                    border
                                    action={
                                        <Button
                                            onClick={() =>
                                                setDoc({ title: reference.name, subTitle: "", src: reference.id })
                                            }
                                            startIcon={<ArrowRightIcon color="secondary" />}
                                        >
                                            {t("open_file")}
                                        </Button>
                                    }
                                />
                            ))}
                        </Stack>
                    </PositionDetailsGroup>
                )}
            </Stack>
            <PositionDetailsActions />
        </StyledDrawer>
    );
};

const StyledDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== "width" })<{
    width: number;
}>(({ theme, width, open }) => ({
    flexShrink: 0,
    width: open ? width : 0,
    zIndex: 1301,
    [theme.breakpoints.up("md")]: {
        zIndex: 1099
    },
    "& .MuiDrawer-paper": {
        width,
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(2),
        overflowY: "visible",
        borderRight: "none",
        zIndex: 1099,
        backgroundColor: theme.palette.background.paper,

        [theme.breakpoints.up("md")]: {
            paddingTop: theme.spacing(10),
            paddingBottom: 16,
            paddingRight: 0,
            paddingLeft: 0
        }
    }
}));

export default PositionDetails;
