// Copyright (C) 2022 TANNER AG

import React, { useEffect, useState, KeyboardEvent } from "react";
import { SearchIcon, CloseIcon } from "../../../components/icons/MANIcons";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import FormInput from "../../../components/inputs/FormInput";
import useStore from "../../../hooks/usestore";
import shallow from "zustand/shallow";

const SearchInput: React.FC = () => {
    const [query, setQuery] = useStore((state) => [state.search?.query ?? "", state.setSearchQuery], shallow);
    const [value, setValue] = useState("");

    useEffect(() => {
        if (value !== query) {
            setValue(query);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    const onKeyDown = (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.key === "Enter") {
            setQuery(event.currentTarget.value);
        }
    };

    const onClose = async () => {
        setValue("");
        setQuery("");
    };

    return (
        <Box display="flex" sx={{ mb: 2 }}>
            <FormInput
                id="search"
                InputProps={{
                    autoFocus: true,
                    fullWidth: true,
                    value,
                    onChange: (event) => setValue(event.currentTarget.value),
                    onKeyDown,
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            {Boolean(value.length) && (
                                <IconButton onClick={onClose} edge="end">
                                    <CloseIcon />
                                </IconButton>
                            )}
                        </InputAdornment>
                    )
                }}
            />
        </Box>
    );
};

export default SearchInput;
