// Copyright (C) 2022 TANNER AG

import React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import MainWrapper from "../../../components/MainWrapper";

const RadarDashboard: React.FC = () => {
    return (
        <MainWrapper>
            <Stack direction="row" sx={{ alignItems: "center", mb: 3 }}>
                <Typography variant="h2">Dashboard</Typography>
            </Stack>
        </MainWrapper>
    );
};

export default RadarDashboard;
