// Copyright (C) 2022 TANNER AG

import React from "react";
import PositionList from "./positions/PositionList";
import MainWrapper from "../../../components/MainWrapper";
import TenderSidebar from "./TenderSidebar";
import { useParams } from "react-router-dom";
import PositionDetails from "./positions/details/PositionDetails";

const TenderDetails: React.FC = () => {
    const { id } = useParams();

    return (
        <MainWrapper sidebarLeft={<TenderSidebar id={String(id)} />} sidebarRight={<PositionDetails />}>
            <PositionList id={String(id)} />
        </MainWrapper>
    );
};

export default TenderDetails;
