// Copyright (C) 2022 TANNER AG

import React from "react";
import useStore from "../../../../../hooks/usestore";
import Button from "@mui/material/Button";
import { AddCircleIcon } from "../../../../../components/icons/MANIcons";
import { UpdateMode, useUpdateCategoryPositionsMutation } from "../../../../../graphql";
import useParam from "../../../../../hooks/useparam";

type Props = {
    id: string;
};

const PositionListItemAdd: React.FC<Props> = ({ id }) => {
    const [cat] = useParam("cat");
    const assignMode = useStore((state) => state.assignMode);
    const [, updatePositions] = useUpdateCategoryPositionsMutation();

    if (!assignMode || !cat) {
        return null;
    }

    const handleClick = async () => {
        await updatePositions({ id: cat ?? "", positions: [id], mode: UpdateMode.Update });
    };

    return (
        <Button onClick={handleClick} startIcon={<AddCircleIcon color="secondary" />}>
            Zur Kategorie hinzufügen
        </Button>
    );
};

export default PositionListItemAdd;
