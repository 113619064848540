// Copyright (C) 2022 TANNER AG

import { TocItem } from "./components/doc/doctoc/types";
import parse from "date-fns/parse";
import format from "date-fns/format";

export const runtimeConfig = {
    basePath: process.env.PUBLIC_URL
};

export const scrollbarSmall = {
    "&::-webkit-scrollbar": {
        width: 6,
        height: 6
    },

    "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        borderRadius: 4
    },

    "&::-webkit-scrollbar-track": {
        backgroundColor: "rgba(0, 0, 0, 0.06)",
        borderRadius: 4
    }
};

export const decodeHtmlEntity = (str: string): string =>
    String(str).replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;");

export const matches = (input = "", value = ""): boolean => {
    if (!input) {
        return false;
    }

    return input.toLowerCase().includes(value.toLowerCase());
};

export const extractEnum = (str: string): [string, string] => {
    const regex = /^(([0-9]+\.?)+)/gm;
    const matches = str.match(regex);
    const enumeration = (matches && matches[0]) || "";

    return [enumeration.trim(), str.substring(enumeration.length).trim()];
};

const mapToc = (map: Map<string, string>, toc: TocItem, anchor: string) => {
    map.set(toc.target.anchorid, anchor);
    if (toc.nodes) {
        toc.nodes.forEach((node) => mapToc(map, node, anchor));
    }
};

export const mapTocs = (tocs: TocItem[] = []): Map<string, string> => {
    const map = new Map<string, string>();
    tocs.forEach((toc) => mapToc(map, toc, toc.target.anchorid));

    return map;
};

export const parseRevDate = (str?: string): string => {
    try {
        if (!str) {
            return "";
        }

        const date = parse(str, "yyyyMMdd", new Date());
        return format(date, "yyyy-MM-dd");
    } catch (err) {
        return str ?? "";
    }
};
