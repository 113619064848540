// Copyright (C) 2022 TANNER AG

import React from "react";
import { Link } from "react-router-dom";
import { runtimeConfig } from "../../utils";
import Box from "@mui/material/Box";

const AppLogo: React.FC = () => {
    return (
        <Box
            component={Link}
            to="/"
            sx={{
                position: "absolute",
                top: { xs: 2, md: 4 },
                width: { xs: 100, md: 145 },
                right: 0,
                zIndex: 1101
            }}
        >
            <Box
                component="img"
                alt="Logo"
                src={`${runtimeConfig.basePath}/logo.svg`}
                sx={{ width: "100%", height: "auto" }}
            />
        </Box>
    );
};

export default AppLogo;
