import {useEffect, useMemo, useState} from "react";
import {
    useAddAllowlistEntryMutation,
    useAllowlistEntriesQuery,
    useDeleteAllowlistEntryMutation,
    useGetAllowlistsQuery
} from "../../../graphql";

export const useAllowlistEntries = (initialLang: string) => {
    const [lang, setLang] = useState(initialLang);
    const [{data: allowlists, fetching: allowllistsFetching}] = useGetAllowlistsQuery();
    const [{data, fetching: allowlistEntriesFetching, error}, refetch] = useAllowlistEntriesQuery({variables: {lang}});
    const [, addAllowlistEntry] = useAddAllowlistEntryMutation();
    const [, deleteAllowlistEntry] = useDeleteAllowlistEntryMutation();

    useEffect(() => {
        refetch({lang});
    }, [refetch, lang]);

    const fetching = useMemo(
        () => allowlistEntriesFetching || allowllistsFetching,
        [allowlistEntriesFetching, allowllistsFetching]
    );

    return {data, allowlists, fetching, error, setLang, addAllowlistEntry, deleteAllowlistEntry};
};
