// Copyright (C) 2022 TANNER AG

import React from "react";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useauth";
import useAnchorHandler from "../../hooks/useanchorhandler";
import MenuPopover from "../MenuPopover";
import MenuList from "@mui/material/MenuList";
import { HelpCircleIcon, LogoutIcon, UserIcon } from "../icons/MANIcons";
import IconButton from "@mui/material/IconButton";
import { useUserQuery } from "../../graphql";
import Stack from "@mui/material/Stack";
import UserAvatar from "./UserAvatar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

const User: React.FC = () => {
    const { logout } = useAuth();
    const [{ data }] = useUserQuery();
    const { anchorEl, onClose, onClick } = useAnchorHandler();

    return (
        <>
            <IconButton size="small" onClick={onClick} color="primary">
                <UserIcon />
            </IconButton>
            <MenuPopover anchorEl={anchorEl} onClose={onClose}>
                <Stack sx={{ px: 2, py: 2, textAlign: "left", alignItems: "center" }} spacing={1} direction="row">
                    <UserAvatar name={data?.user?.name || data?.user?.username} />
                    <Box>
                        <Typography component="div" variant="subtitle2" color="textPrimary" sx={{ fontSize: 16 }}>
                            {data?.user?.name || data?.user?.username}
                        </Typography>
                    </Box>
                </Stack>
                <Divider />
                <MenuList component="div">
                    <MenuItem component={Link} to="/contact">
                        <ListItemIcon>
                            <HelpCircleIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <ListItemText primaryTypographyProps={{ variant: "body2" }}>Help</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={logout}>
                        <ListItemIcon>
                            <LogoutIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <ListItemText primaryTypographyProps={{ variant: "body2" }}>Logout</ListItemText>
                    </MenuItem>
                </MenuList>
            </MenuPopover>
        </>
    );
};

export default User;
