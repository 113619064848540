// Copyright (C) 2020 TANNER AG

import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import Box from "@mui/material/Box";

type Props = {
    src?: string;
    className?: string;
    alt?: string;
};

const PdfViewer: React.FC<Props> = ({ alt, className, src }) => {
    const [key, setKey] = useState(0);

    useEffect(() => {
        setKey((key) => key + 1);
    }, [src]);

    if (!src) {
        return null;
    }

    return (
        <Box
            key={key}
            component="iframe"
            src={src}
            frameBorder="0"
            sx={{ width: "100%", height: "calc(100% - 8px)" }}
            className={clsx(className)}
            data-key={key}
        >
            <Typography>
                <a href={src} target="_blank" rel="noopener noreferrer">
                    {alt || "Download"}
                </a>
            </Typography>
        </Box>
    );
};

export default PdfViewer;
