// Copyright (C) 2022 TANNER AG

import React from "react";
import Button from "@mui/lab/LoadingButton";
import { InfoItem } from "../../../graphql";
import { ArrowRightIcon } from "../../../components/icons/MANIcons";
import useStore from "../../../hooks/usestore";

type Props = {
    item: InfoItem;
};

const ResultListItemAction: React.FC<Props> = ({ item }) => {
    const setDoc = useStore((state) => state.setDoc);

    const handleClick = () => {
        setDoc({ title: item.title, subTitle: item.subtitle ?? "", src: item.target.link ?? "" });
    };

    return (
        <Button onClick={handleClick} startIcon={<ArrowRightIcon color="secondary" />}>
            Show details
        </Button>
    );
};

export default ResultListItemAction;
