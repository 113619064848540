// Copyright (C) 2021-2022 TANNER AG

import { createClient } from "urql";

export const getToken = () => window.localStorage.getItem("access-token");

export const client = createClient({
    url: `${process.env.REACT_APP_SERVICE_URL ?? ""}/graphql`,
    fetchOptions: () => {
        const token = getToken();
        return {
            headers: { Authorization: token ? `Bearer ${token}` : "" }
        };
    }
});
