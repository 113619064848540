import {useEffect, useState} from "react";
import {
    useAddCpvItemMutation,
    useAddOriginItemMutation,
    useDeleteCpvItemMutation,
    useDeleteOriginItemMutation,
    useQueryParametersQuery
} from "../../../graphql";

export const useCategoriesOrigins = (initialProjectType: string) => {
    const [projectType, setProjectType] = useState<string>(initialProjectType);
    const [{data: categoriesData}, categoriesOriginsRefetch] = useQueryParametersQuery();
    const [, addCpv] = useAddCpvItemMutation();
    const [, deleteCpv] = useDeleteCpvItemMutation();
    const [, addOrigin] = useAddOriginItemMutation();
    const [, deleteOrigin] = useDeleteOriginItemMutation();

    useEffect(() => {
        categoriesOriginsRefetch({projectType});
    }, [categoriesOriginsRefetch, projectType]);

    const addCpvProxy = (cpv: string) =>
        addCpv({projectType, cpv}).then(() => categoriesOriginsRefetch({requestPolicy: "network-only"}));

    const deleteCpvProxy = (cpv: string) =>
        deleteCpv({projectType, cpv}).then(() => categoriesOriginsRefetch({requestPolicy: "network-only"}));

    const addOriginProxy = (origin: string) =>
        addOrigin({projectType, origin}).then(() => categoriesOriginsRefetch({requestPolicy: "network-only"}));

    const deleteOriginProxy = (origin: string) =>
        deleteOrigin({projectType, origin}).then(() => categoriesOriginsRefetch({requestPolicy: "network-only"}));

    return {
        categoriesData: categoriesData?.queryParameters,
        addCpv: addCpvProxy,
        deleteCpv: deleteCpvProxy,
        addOrigin: addOriginProxy,
        deleteOrigin: deleteOriginProxy,
        setProjectType
    };
};
