// Copyright (C) 2022 TANNER AG

import localeDE from "date-fns/locale/de";
import localeEN from "date-fns/locale/en-US";

export const TRANSLATION: {
    [locale: string]: { label: string; dateLocale: Locale; statics: { [key: string]: string } };
} = {
    en: {
        label: "English",
        dateLocale: localeEN,
        statics: {
            home: "Home",
            tenders: "Tenders",
            create_tender: "Create tender",
            total_tenders: "{total} tenders",
            created: "Created",
            created_by: "Created by",
            last_updated: "Last updated",
            show_details: "Show details",
            filter_positions: "Filter positions",
            search_positions: "Description, notes",
            total_positions: "{total} positions",
            total_documents: "Total documents",
            open_file: "Open file",
            back_to_overview: "Back to overview",
            positions: "Positions",
            documents: "Documents",
            show_all: "Show all ({total})",
            hide_all: "Hide all ({total})",
            add_category: "Add custom category",
            order_positions: "Position zuordnen",
            cancel: "Cancel",
            create_category: "Create Category",
            products_services: "E-MOBILITY PRODUCTS & SERVICES",
            products_solutions: "Products & Solutions"
        }
    },
    de: {
        label: "Deutsch",
        dateLocale: localeDE,
        statics: {
            home: "Home",
            tenders: "Ausschreibungen",
            create_tender: "Ausschreibung anlegen",
            total_tenders: "{total} Ausschreibungen",
            created: "Erstellt am",
            created_by: "Erstellt von",
            last_updated: "Zuletzt geändert",
            show_details: "Details anzeigen",
            filter_positions: "Lastenheft filtern",
            search_positions: "Bezeichnungen und Bemerkungen",
            total_positions: "{total} Lasten",
            total_documents: "Anzahl Dokumente",
            open_file: "Datei öffnen",
            back_to_overview: "Zurück zur Übersicht",
            positions: "Positionen",
            documents: "Dokumente",
            show_all: "Alle anzeigen ({total})",
            hide_all: "Alle ausblenden ({total})",
            add_category: "Kategorie hinzufügen",
            order_positions: "Positionen anpassen",
            cancel: "Abbrechen",
            create_category: "Kategorie erstellen",
            products_services: "E-MOBILITY PRODUCTS & SERVICES",
            products_solutions: "Products & Solutions",
            suitability: "Eignung",
            technical_professional_info: "Technische Fachinformation",
            economic_financial_info: "Wirtschaftliche und finanzielle Informationen",
            performance_conditions: "Leistungsbedingungen",
            qualifications: "Qualifizierung für das System"
        }
    }
};
