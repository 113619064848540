// Copyright (C) 2022 TANNER AG

import React, { KeyboardEvent, useEffect, useState } from "react";
import FormInput from "../../../components/inputs/FormInput";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { SearchIcon, CloseIcon } from "../../../components/icons/MANIcons";
import useParam from "../../../hooks/useparam";
import { useSearchParams } from "react-router-dom";

const RadarListSearch: React.FC = () => {
    const [, setQuery] = useParam("q");
    const [searchParams, setSearchParams] = useSearchParams();
    const [value, setValue] = useState("");

    const query = searchParams.get("q") ?? "";

    useEffect(() => {
        if (value !== query) {
            setValue(query);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    const onKeyDown = (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.key === "Enter") {
            const newSearchParams = new URLSearchParams(searchParams);
            newSearchParams.delete("page");
            newSearchParams.set("q", event.currentTarget.value);
            setSearchParams(newSearchParams);
        }
    };

    const onClear = () => {
        setQuery("");
        setQuery("");
    };

    return (
        <FormInput
            id="search"
            label="Stichwortsuche"
            InputProps={{
                value,
                onChange: (event) => setValue(event.currentTarget.value),
                onKeyDown,
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                        {Boolean(query.length) && (
                            <IconButton onClick={onClear}>
                                <CloseIcon sx={{ fontSize: 14 }} />
                            </IconButton>
                        )}
                    </InputAdornment>
                )
            }}
        />
    );
};

export default RadarListSearch;
