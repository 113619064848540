// Copyright (C) 2022 TANNER AG

import React from "react";
import Container from "@mui/material/Container";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { styled } from "@mui/material";
import clsx from "clsx";
import Stack from "@mui/material/Stack";

const LINKS = [
    {
        label: "Corporate Information",
        href: "#"
    },
    {
        label: "Privacy Policy",
        href: "#"
    },
    {
        label: "Terms of Use",
        href: "#"
    },
    {
        label: "Digital ID",
        href: "#"
    }
];

type Props = {
    marginLeft?: boolean;
};

const Footer: React.FC<Props> = ({ marginLeft }) => {
    return (
        <Root className={clsx({ marginLeft })}>
            <Container sx={{ my: 2 }}>
                <Stack direction="row" sx={{ justifyContent: "space-between" }}>
                    <Breadcrumbs separator="|" sx={{ pt: 2, borderColor: "divider" }}>
                        <Typography sx={{ fontSize: "0.8rem" }} variant="body2">
                            C42 © {new Date().getFullYear()}
                        </Typography>
                        <Typography
                            sx={{ fontSize: "0.8rem" }}
                            variant="body2"
                            title={process.env.REACT_APP_BUILD_NUMBER}
                        >
                            v{process.env.REACT_APP_BUILD_NUMBER?.split("+")[0]}
                        </Typography>
                    </Breadcrumbs>
                    <Breadcrumbs separator="|" sx={{ pt: 2, borderColor: "divider" }}>
                        {LINKS.map((link) => (
                            <Link
                                key={link.label}
                                href={link.href}
                                sx={{ fontSize: "0.8rem" }}
                                color="inherit"
                                target="_blank"
                            >
                                {link.label}
                            </Link>
                        ))}
                    </Breadcrumbs>
                </Stack>
            </Container>
        </Root>
    );
};

const Root = styled("footer")(({ theme }) => ({
    position: "absolute",
    bottom: 0,
    width: "100%",
    backgroundColor: "background.default",
    mt: 4,

    "&.marginLeft": {
        [theme.breakpoints.up("md")]: {
            paddingLeft: 400
        }
    }
}));

export default Footer;
