// Copyright (C) 2022 TANNER AG

import React from "react";
import { Routes, Route } from "react-router-dom";
import SubAppBar from "../../components/appbar/SubAppBar";
import RadarDashboard from "./dashboard/RadarDashboard";
import RadarConfig from "./configuration/RadarConfig";

const RadarView: React.FC = () => {
    return (
        <>
            <SubAppBar />
            <Routes>
                <Route path="/" element={<RadarDashboard />} />
                <Route path="/configuration" element={<RadarConfig />} />
            </Routes>
        </>
    );
};

export default RadarView;
