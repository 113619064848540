// Copyright (C) 2022 TANNER AG

import create from "zustand";
import { TocItem } from "../components/doc/doctoc/types";
import { mapTocs } from "../utils";

export type ItemType = "item" | "graphic" | "product";

type DocumentStore = {
    docId: string;
    tocMap?: Map<string, string>;
    toc: TocItem[];
    openToc: boolean;
    query?: string;
    productId?: string;
    activeImage?: string;
    activeItemData?: string;
    activeItemType?: ItemType;
    drawerOpen: boolean;
    setActiveImage(activeImage?: string): void;
    setActiveItemData(itemData?: string, type?: ItemType): void;
    setOpenToc(openToc: boolean): void;
    setToc(toc?: TocItem[]): void;
    initDoc(docId?: string): void;
};

const useDocStore = create<DocumentStore>((set) => ({
    docId: "",
    toc: [],
    openToc: false,
    drawerOpen: false,
    setActiveImage: (activeImage) => set(() => ({ activeImage })),
    setActiveItemData: (itemData, type) =>
        set(() => ({ activeItemData: itemData, activeItemType: type, drawerOpen: Boolean(itemData) })),
    setOpenToc: (openToc) => set(() => ({ openToc })),
    setToc: (toc = []) => set(() => ({ toc, tocMap: mapTocs(toc) })),
    initDoc: (docId = "") => set(() => ({ docId }))
}));

export default useDocStore;
