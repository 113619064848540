// Copyright (C) 2023 TANNER AG

import React from "react";
import Toolbar from "@mui/material/Toolbar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {categoriesTypes} from "./constants";

type Props = {
    index: string;
    setIndex(index: string): void;
};

const CategoryTabBar: React.FC<Props> = ({index, setIndex}) => {
    return (
        <Toolbar disableGutters variant="dense">
            <Tabs
                value={index}
                indicatorColor="secondary"
                onChange={(_, newIndex) => setIndex(newIndex)}
                sx={{width: 1}}
            >
                {[
                    {label: "Bus", value: categoriesTypes.bus},
                    {label: "Marktinfos", value: categoriesTypes.marketInfo}
                ].map(({label, value}, index) => (
                    <Tab key={index} label={label} value={value} />
                ))}
            </Tabs>
        </Toolbar>
    );
};

export default CategoryTabBar;
