// Copyright (C) 2022 TANNER AG

import React, { FormEvent, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/lab/LoadingButton";
import LoginInput from "./LoginInput";
import { runtimeConfig } from "../../utils";
import CssBaseline from "@mui/material/CssBaseline";
import ErrorAlert from "../../components/error/ErrorAlert";

type Props = {
    onSuccess?(): void;
};

const Login: React.FC<Props> = ({ onSuccess }) => {
    const [fetching, setFetching] = useState(false);
    const [error, setError] = useState("");

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setFetching(true);

        try {
            const formData = new FormData(event.currentTarget);
            const response = await fetch(`${process.env.REACT_APP_SERVICE_URL ?? ""}/api/login`, {
                method: "POST",
                headers: { "Content-Type": "application/Json" },
                body: JSON.stringify({
                    username: formData.get("username") as string,
                    password: formData.get("password") as string
                })
            });
            if (!response.ok) {
                setError(await response.text());
                return;
            }

            const data: { accessToken?: string } = await response.json();

            if (!data?.accessToken) {
                return;
            }

            window.localStorage.setItem("access-token", data.accessToken);
            onSuccess?.();
        } catch (err: any) {
            setError(err);
        } finally {
            setFetching(false);
        }
    };

    return (
        <>
            <CssBaseline />
            <Box sx={{ display: "flex", height: "100vh" }}>
                <Box
                    style={{ backgroundImage: `url(${runtimeConfig.basePath}/login-banner.png)` }}
                    sx={{ width: 750, backgroundPosition: "center", backgroundSize: "cover" }}
                />
                <Box
                    sx={{
                        display: "flex",
                        flexGrow: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                        px: 2
                    }}
                >
                    <Box sx={{ width: "100%", maxWidth: 450 }}>
                        <form onSubmit={handleSubmit}>
                            <Box sx={{ mb: 3 }}>
                                <Typography variant="h5" gutterBottom>
                                    Sign in to C42 Infoportal
                                </Typography>
                                <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                                    Enter your details below.
                                </Typography>
                            </Box>
                            <LoginInput name="username" label="Username" fullWidth margin="normal" variant="filled" />
                            <LoginInput
                                name="password"
                                label="Password"
                                fullWidth
                                margin="normal"
                                variant="filled"
                                type="password"
                            />
                            {error && <ErrorAlert title={error} sx={{ mt: 1 }} />}
                            <Box sx={{ mt: 4, textAlign: "center" }}>
                                <Button type="submit" variant="contained" color="primary" fullWidth loading={fetching}>
                                    Log in
                                </Button>
                            </Box>
                        </form>
                    </Box>
                    <Typography variant="caption" sx={{ position: "absolute", bottom: 0, left: 0, my: 1, mx: 2 }}>
                        C42 © {new Date().getFullYear()}
                    </Typography>
                </Box>
                <Box
                    component="img"
                    sx={{ position: "absolute", top: 0, left: 0, width: 80, my: 1, mx: 1 }}
                    src={`${runtimeConfig.basePath}/c42_white.svg`}
                    alt="C42 Logo"
                />
            </Box>
        </>
    );
};

export default Login;
