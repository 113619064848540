// Copyright (C) 2022 TANNER AG

import React from "react";
import { Routes, Route } from "react-router-dom";
import TenderOverview from "./overview/TenderOverview";
import TenderDetails from "./details/TenderDetails";

const TenderView: React.FC = () => {
    return (
        <Routes>
            <Route path="/" element={<TenderOverview />} />
            <Route path=":id" element={<TenderDetails />} />
        </Routes>
    );
};

export default TenderView;
