// Copyright (C) 2021 TANNER AG

import { useCallback } from "react";
import useLocale from "./uselocale";

type TranslationParams = {
    [name: string]: number | string;
};

const useTranslation = () => {
    const { statics } = useLocale();

    const t = useCallback(
        (key: string, params?: TranslationParams): string => {
            if (!statics) {
                return "...";
            }

            if (statics.hasOwnProperty(key)) {
                let translation = statics[key];
                for (const parameter in params) {
                    if (!params.hasOwnProperty(parameter)) {
                        continue;
                    }
                    translation = translation.replace(`{${parameter}}`, params[parameter].toString());
                }

                return translation;
            }

            return "...";
        },
        [statics]
    );

    return { t };
};

export default useTranslation;
