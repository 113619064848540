// Copyright (C) 2022 TANNER AG

import React from "react";
import Stack from "@mui/material/Stack";
import { usePositionCategoriesQuery } from "../../../../graphql";
import CategoryAdd from "./CategoryAdd";
import { scrollbarSmall } from "../../../../utils";
import CategoryListItem from "./CategoryListItem";

type Props = {
    showCustom?: boolean;
};

const CategoryList: React.FC<Props> = ({ showCustom }) => {
    const [{ data }] = usePositionCategoriesQuery({ variables: { id: "" } });
    const categories = data?.positionCategories?.filter((cat) => (showCustom ? cat.custom : !cat.custom)) ?? [];

    return (
        <Stack sx={{ flexGrow: 1, overflow: "auto", ...scrollbarSmall }}>
            {categories.map((category) => (
                <CategoryListItem
                    key={category.id}
                    id={category.id}
                    name={category.name}
                    icon={category.icon}
                    custom={category.custom}
                    total={category.positions?.length ?? 0}
                />
            ))}
            {showCustom && (
                <Stack direction="row-reverse" sx={{ mt: 2 }}>
                    <CategoryAdd />
                </Stack>
            )}
        </Stack>
    );
};

export default CategoryList;
