// Copyright (C) 2020 TANNER AG

import React, { MouseEvent } from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";

type Props = {
    className?: string;
};

const GalleyLink: React.FC<Props> = ({ className, children, ...others }) => {
    const navigate = useNavigate();

    const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        navigate({ search: new URLSearchParams({ product: event.currentTarget.text }).toString() });
    };
    return (
        <a className={clsx("chapter-link", className)} onClick={handleClick} {...others}>
            {children}
        </a>
    );
};

export default GalleyLink;
