import React from "react";
import {Grid, styled, Typography} from "@mui/material";
import {boldFontFamily} from "../../../theme";
import {AllowListEntry} from "./radarConfig.types";
import {CheckCircleIcon, DeleteIcon} from "../../../components/icons/MANIcons";
import {languageMap} from "./constants";

const ListWrapper = styled(Grid)(({theme}) => ({
    "&:nth-of-type(even)": {
        backgroundColor: theme.palette.grey[50]
    }
}));

export type RadarFilterLineProps = {
    item: AllowListEntry;
    onRemoveClick: (id: string) => void;
};

const RadarFilterLine = ({item, onRemoveClick}: RadarFilterLineProps) => {
    const {id, value, lang} = item;
    const handleRemoveClick = () => {
        onRemoveClick(id);
    };

    return (
        <ListWrapper container padding={1}>
            <Grid item xs={2}>
                <Typography fontFamily={boldFontFamily} color="textSecondary">
                    {languageMap[lang as keyof typeof languageMap]}
                </Typography>
            </Grid>
            <Grid item xs={8} display="flex" gap={1} alignItems="center">
                <CheckCircleIcon color="success" fontSize="small" />
                <Typography>{value}</Typography>
            </Grid>
            <Grid item xs={2} justifyContent="end" display="flex">
                <DeleteIcon color="error" onClick={handleRemoveClick} fontSize="small" />
            </Grid>
        </ListWrapper>
    );
};

export default RadarFilterLine;
