// Copyright (C) 2022 TANNER AG

import React from "react";
import { FILTER } from "./Filter";
import FilterActiveParam from "./FilterActiveParam";
import Stack from "@mui/material/Stack";

const FilterActive: React.FC = () => {
    return (
        <Stack direction="row" spacing={1}>
            {Object.values(FILTER).map(({ field, label }) => (
                <FilterActiveParam key={field} label={label} field={field} />
            ))}
        </Stack>
    );
};

export default FilterActive;
