import React from "react";
import Toolbar from "@mui/material/Toolbar";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import FormSelect from "../../../components/inputs/FormSelect";
import RadarListSearch from "../overview/RadarListSearch";
import useParam from "../../../hooks/useparam";
import {languageArrayType} from "./radarConfig.types";

type RadarConfigToolbarProps = {
    languages: languageArrayType;
};

const RadarConfigToolbar = ({languages}: RadarConfigToolbarProps) => {
    const [language, setLanguage] = useParam("lang");
    return (
        <Toolbar disableGutters sx={{mb: 2}}>
            <Stack direction="row" spacing={1}>
                <Box sx={{width: 260}}>
                    <FormSelect
                        id="language"
                        label="Sprachen filtern"
                        options={[{label: "Alle", value: ""}, ...languages]}
                        SelectProps={{
                            value: language,
                            onChange: (event) => setLanguage(event.currentTarget.value)
                        }}
                    />
                </Box>
            </Stack>
            <Box sx={{width: 300, ml: "auto"}}>
                <RadarListSearch />
            </Box>
        </Toolbar>
    );
};

export default RadarConfigToolbar;
