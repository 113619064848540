// Copyright (C) 2023 TANNER AG

import React, {useMemo, useRef, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import MuiAppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {Alert, AlertColor, Box, Button, Snackbar} from "@mui/material";
import {DownloadIcon} from "../icons/MANIcons";
import {getToken} from "../../lib/urql";
import {downloadFile} from "../../lib/utils";

type FileFormat = "json" | "xlsx";

const fetchFile = async (format: FileFormat): Promise<any> => {
    try {
        const url = `${process.env.REACT_APP_SERVICE_URL ?? ""}/api/tenders/splist?format=${format}`;
        const response = await fetch(url.toString(), {
            method: "GET",
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        });
        return response.blob();
    } catch (e) {
        console.log(e);
        return false;
    }
};

const uploadFile = async (file:any): Promise<any> =>{
    try{
        console.log(file);
        const url = `${process.env.REACT_APP_SERVICE_URL ?? ""}/api/tenders/upload`;
        const response = await fetch(url.toString(),{
            method:"POST",
            headers:{
                Authorization: `Bearer ${getToken()}`
            },
            body:file,
        });
        return response.json();
    } catch (e) {
        console.log(e);
        return false;
    }
}

const SubAppBar = () => {
    const inputRef = useRef<HTMLInputElement | undefined>(undefined) as React.MutableRefObject<HTMLInputElement>;
    const {pathname} = useLocation();
    const [isFetching, setFetching] = useState(false);
    const [notification, setNotification] = useState<{[key: string]: string | AlertColor} | null>(null);
    const value = useMemo(() => {
        return ["/radar", "/radar/configuration"].find((route) => pathname === route) ?? "/radar";
    }, [pathname]);

    const handleDownloadClick = (format: FileFormat) => async () => {
        setFetching(true);
        setNotification({message: "Datei wird erstellt...", type: "info"});
        const data = await fetchFile(format);
        if (data) downloadFile(data, `data.${format}`);
        setFetching(false);
        setNotification({message: "Datei wird heruntergeladen", type: "success"});
    };

    const handleUploadClick = () => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    }

    const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setFetching(true);
            const formData = new FormData();
            formData.append("fileContent", file);
            uploadFile(formData);
            setNotification({message: "File is uploaded", type: "info"});
        } else {
            setNotification({message: "No file selected", type: "error"});
        }
        setFetching(false);
    }

    const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }
        setNotification(null);
    };

    return (
        <>
            {notification && (
                <Snackbar
                    anchorOrigin={{vertical: "top", horizontal: "right"}}
                    open={!!notification}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                >
                    <Alert
                        severity={notification.type as AlertColor}
                        sx={{width: "100%"}}
                        onClose={handleCloseSnackbar}
                    >
                        {notification.message}
                    </Alert>
                </Snackbar>
            )}
            <MuiAppBar
                position="sticky"
                elevation={0}
                sx={{backgroundColor: "background.paper", color: "text.secondary"}}
            >
                <Container>
                    <Toolbar disableGutters variant="dense">
                        <Tabs value={value} indicatorColor="secondary" sx={{flexGrow: 1}}>
                            {[
                                {label: "Dashboard", to: "/radar"},
                                {label: "Konfiguration", to: "/radar/configuration"}
                            ].map(({label, to}, index) => (
                                <Tab key={index} label={label} value={to} component={Link} to={to} />
                            ))}
                        </Tabs>

                        <Box
                            sx={{
                                ml: "auto",
                                mr: {sm: "100px", md: "150px"},
                                pr: 2,
                                pb: 1,
                                alignSelf: "flex-end",
                                display: {xs: "none", sm: "flex"}
                            }}
                        >
                            <input ref={inputRef}
                                   style={{display: "none"}}
                                   type="file"
                                   accept={".xlsx"}
                                   onChange={onFileChange}/>
                            <Button
                                variant="text"
                                color="primary"
                                size="small"
                                sx={{width: "100%"}}
                                startIcon={<DownloadIcon style={{transform: "rotate(180deg)"}} color="error" />}
                                onClick={handleUploadClick}
                            >
                                Import&nbsp;(XLSX)
                            </Button>
                            <Button
                                variant="text"
                                color="primary"
                                size="small"
                                sx={{width: "100%"}}
                                startIcon={<DownloadIcon color="error" />}
                                onClick={handleDownloadClick("xlsx")}
                                disabled={isFetching}
                            >
                                Export&nbsp;(XLSX)
                            </Button>
                            <Button
                                variant="text"
                                color="primary"
                                size="small"
                                sx={{width: "100%"}}
                                startIcon={<DownloadIcon color="error" />}
                                onClick={handleDownloadClick("json")}
                                disabled={isFetching}
                            >
                                Export&nbsp;(JSON)
                            </Button>
                        </Box>
                    </Toolbar>
                </Container>
            </MuiAppBar>
        </>
    );
};

export default SubAppBar;
