// Copyright (C) 2022 TANNER AG

import React from "react";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";

type Props = {
    actions?: React.ReactNode;
    metadata?: React.ReactNode;
};

const ResultListWrapper: React.FC<Props> = ({ actions, metadata, children }) => {
    return (
        <Stack
            component={Paper}
            direction="row"
            sx={{
                position: "relative",
                transition: "all .3s",
                backgroundColor: "background.paper",
                "&.selected": { border: "1px solid", borderColor: "secondary.main" }
            }}
        >
            <Stack sx={{ p: 1, pt: 1.5, pl: 1.5, flexGrow: 1 }}>
                <Stack sx={{ mr: 6 }}>{children}</Stack>
                <Stack direction="row" sx={{ justifyContent: "space-between", alignItems: "center", mt: 0.5 }}>
                    <div>{metadata}</div>
                    <div>{actions}</div>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default ResultListWrapper;
