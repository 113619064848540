// Copyright (C) 2022 TANNER AG

import React from "react";
import Stack from "@mui/material/Stack";
import User from "../user/User";
import LocaleSwitch from "./LocaleSwitch";

const AppToolbar = () => {
    return (
        <Stack
            spacing={1}
            direction="row"
            sx={{
                ml: "auto",
                mr: { sm: "100px", md: "150px" },
                pr: 2,
                pb: 1,
                alignSelf: "flex-end",
                display: { xs: "none", sm: "flex" }
            }}
        >
            <LocaleSwitch />
            <User />
        </Stack>
    );
};

export default AppToolbar;
