// Copyright (C) 2022 TANNER AG

import { useSearchParams } from "react-router-dom";
import { useTendersQuery } from "../../../graphql";
import { RESULTS_PER_PAGE } from "../details/positions/Pagination";
import { useMemo } from "react";
import {alpha3ToAlpha2} from "i18n-iso-countries";

const useTenders = (disablePagination = false) => {
    const [searchParams] = useSearchParams();
    const page = Number(searchParams.get("page") ?? "1");
    const country = searchParams.get("country") ?? "";
    const drivingConcept = searchParams.get("drivingConcept") ?? "";
    const query = searchParams.get("q") ?? "";
    const start = (page - 1) * RESULTS_PER_PAGE;

    const [{ data, fetching, error }] = useTendersQuery();
    const [tenders, countries, drivingConcepts] = useMemo(() => {
        const countries: string[] = [];
        const drivingConcepts: string[] = [];
        data?.tenders?.forEach((tender) => {
            const countryAlpha2 = alpha3ToAlpha2(tender.country);
            countries.push(countryAlpha2);
            drivingConcepts.push(...tender.drivingConcept);
        });

        return [
            data?.tenders?.filter(
                (tender) =>
                    (!country || tender.country === country) &&
                    (!drivingConcept || tender.drivingConcept?.includes(drivingConcept)) &&
                    (!query ||
                        tender.ojs?.toLowerCase()?.includes(query) ||
                        tender.buyer?.name?.toLowerCase()?.includes(query))
            ) ?? [],
            // @ts-ignore
            [...new Set(countries)],
            // @ts-ignore
            [...new Set(drivingConcepts)]
        ];
    }, [data?.tenders, country, drivingConcept, query]);

    return {
        tenders: disablePagination ? tenders : tenders.slice(start, start + RESULTS_PER_PAGE) ?? [],
        total: tenders.length,
        countries,
        drivingConcepts,
        fetching,
        error
    };
};

export default useTenders;
