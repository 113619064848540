// Copyright (C) 2022 TANNER AG

import React, { useEffect, useState } from "react";
import Button from "@mui/lab/LoadingButton";
import { AddCircleIcon } from "../../../../components/icons/MANIcons";
import useTranslation from "../../../../hooks/usetranslation";
import Dialog from "../../../../components/dialog/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogHead from "../../../../components/dialog/DialogHead";
import DialogActions from "@mui/material/DialogActions";
import FormInput from "../../../../components/inputs/FormInput";
import { useCreateCategoryMutation } from "../../../../graphql";

const CategoryAdd: React.FC = () => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [name, setName] = useState("");
    const [{ fetching }, createCategory] = useCreateCategoryMutation();

    const handleCreation = async () => {
        if (!name) {
            return;
        }

        await createCategory({ data: { name } });
        setOpen(false);
    };

    useEffect(() => {
        setName("");
    }, [open]);

    return (
        <>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogContent>
                    <DialogHead title={t("add_category")} onClose={() => setOpen(false)} />
                    <FormInput
                        id=""
                        label="Name"
                        InputProps={{
                            value: name,
                            onChange: (event) => setName(event.currentTarget.value),
                            autoFocus: true
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCreation}
                        loading={fetching}
                        variant="contained"
                        color="secondary"
                        startIcon={<AddCircleIcon />}
                    >
                        {t("create_category")}
                    </Button>
                    <Button onClick={() => setOpen(false)} disabled={fetching}>
                        {t("cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
            <Button onClick={() => setOpen(true)} startIcon={<AddCircleIcon />} variant="contained" color="secondary">
                {t("add_category")}
            </Button>
        </>
    );
};

export default CategoryAdd;
