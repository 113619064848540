// Copyright (C) 2022 TANNER AG

import React from "react";
import Toolbar from "@mui/material/Toolbar";
import MuiAppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

type Props = {
    index: number;
    setIndex(index: number): void;
};

const TenderTabBar: React.FC<Props> = ({ index, setIndex }) => {
    return (
        <MuiAppBar
            position="static"
            elevation={0}
            sx={{ backgroundColor: "background.paper", color: "text.secondary", mb: 2 }}
        >
            <Toolbar disableGutters variant="dense">
                <Tabs
                    value={index}
                    indicatorColor="secondary"
                    onChange={(_, newIndex) => setIndex(newIndex)}
                    variant="fullWidth"
                    sx={{ width: 1 }}
                >
                    {[{ label: "Standard Kategorien" }, { label: "Persönliche Kategorien" }].map(({ label }, index) => (
                        <Tab key={index} label={label} value={index} />
                    ))}
                </Tabs>
            </Toolbar>
        </MuiAppBar>
    );
};

export default TenderTabBar;
