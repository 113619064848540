// Copyright (C) 2021 TANNER AG

import React from "react";
import Container from "@mui/material/Container";
import { styled } from "@mui/material";
import { scrollbarSmall } from "../lib/utils";

const Main = styled("div")(({ theme }) => ({
    display: "flex",
    paddingBottom: theme.spacing(10),
    height: "100%",
    overflow: "auto",
    ...scrollbarSmall,

    "& .main-content": {
        flexGrow: 1,
        marginTop: theme.spacing(2),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    }
}));

type Props = {
    sidebarLeft?: JSX.Element;
    sidebarRight?: JSX.Element;
    footer?: boolean;
};

const MainWrapper: React.FC<Props> = ({ sidebarLeft, sidebarRight, footer, children }) => {
    return (
        <Main id="scroller">
            {sidebarLeft}
            <div className="main-content">
                <Container sx={{ maxWidth: { md: 1200 } }}>{children}</Container>
            </div>
            {sidebarRight}
        </Main>
    );
};

export default MainWrapper;
