// Copyright (C) 2022 TANNER AG

import React, { useState } from "react";

const useAnchorHandler = <T extends HTMLElement>() => {
    const [anchorEl, setAnchorEl] = useState<null | T>(null);

    const handleAnchor = (el?: T | null) => {
        setAnchorEl(el ?? null);
    };

    const onClick = (event: React.MouseEvent<T>) => {
        handleAnchor(event.currentTarget);
    };

    const onClose = () => handleAnchor();

    return { anchorEl, onClose, onClick, handleAnchor };
};

export default useAnchorHandler;
