// Copyright (C) 2022 TANNER AG

import { createTheme as createMuiTheme } from "@mui/material/styles";

export const regularFontFamily = ["MAN-regular", "sans-serif", "-apple-system"].join(",");
export const lightFontFamily = ["MAN-light", "sans-serif", "-apple-system"].join(",");
export const boldFontFamily = ["MAN-bold", "sans-serif", "-apple-system"].join(",");
export const arialFontFamily = ["Arial", "sans-serif", "-apple-system"].join(",");

const createTheme = (mode?: "light" | "dark") =>
    createMuiTheme({
        typography: {
            fontFamily: lightFontFamily,
            fontWeightBold: 700,
            button: {
                fontFamily: arialFontFamily,
                fontSize: "0.875rem",
                fontWeight: 700,
                lineHeight: "14px",
                textTransform: "none"
            },
            h1: {
                fontFamily: boldFontFamily,
                textTransform: "uppercase",
                fontSize: 36,
                "@media (min-width:900px)": {
                    fontSize: 60
                }
            },
            h2: {
                fontFamily: boldFontFamily,
                textTransform: "uppercase",
                fontSize: 36,
                "@media (min-width:900px)": {
                    fontSize: 48
                }
            },
            h3: {
                fontFamily: boldFontFamily,
                fontSize: 18,
                "@media (min-width:900px)": {
                    fontSize: 28
                }
            },
            h4: {
                fontFamily: boldFontFamily,
                fontSize: 20
            },
            h5: {
                fontFamily: boldFontFamily,
                fontSize: 18
            },
            subtitle1: {
                fontFamily: lightFontFamily,
                fontSize: 18
            },
            subtitle2: {
                fontFamily: boldFontFamily,
                fontSize: 14
            }
        },
        palette: {
            mode,
            primary: {
                contrastText: "#fff",
                main: "#303c49"
            },
            secondary: {
                contrastText: "#fff",
                main: "#e40045",
                dark: "#bb0039"
            },
            text: {
                /* grey.900 */
                primary: "#1e2832",
                /* grey.500 */
                secondary: "#5b7085"
            },
            grey: {
                50: "#eaedf3",
                100: "#cbd3dc",
                200: "#abb6c3",
                300: "#8b99aa",
                500: "#5b7085",
                800: "#303c49",
                900: "#1e2832"
            },
            success: {
                main: "#91b900"
            }
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: `
                    body {
                      background-color: #eaedf3;
                      position: relative;
                      min-height: 100vh;
                    }
                    p {
                      margin: 0;
                    }
                `
            },
            MuiContainer: {
                defaultProps: {
                    // maxWidth: false
                }
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        borderRadius: 0,
                        boxShadow: "none"
                    },
                    sizeMedium: {
                        padding: "10px 16px",
                        height: 40
                    },
                    outlined: {
                        backgroundColor: "#fff"
                    },
                    startIcon: {
                        fontSize: 18,
                        "& > *:nth-of-type(1)": {
                            fontSize: 18
                        }
                    },
                    endIcon: {
                        fontSize: 18,
                        "& > *:nth-of-type(1)": {
                            fontSize: 18
                        }
                    }
                }
            },
            MuiTabs: {
                styleOverrides: {
                    indicator: {
                        height: 4,
                        backgroundColor: "#1e2832"
                    }
                }
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        paddingRight: 0,
                        paddingLeft: 0,
                        marginRight: 32,
                        textTransform: "none",
                        fontFamily: lightFontFamily,
                        fontSize: 16,
                        color: "#1e2832",
                        minWidth: "auto",
                        "&.Mui-selected": {
                            color: "#1e2832"
                        }
                    },
                    labelIcon: {
                        minHeight: 48
                    }
                }
            },
            MuiPaper: {
                defaultProps: {
                    variant: "outlined"
                },
                styleOverrides: {
                    root: {
                        // borderRadius: 0
                    },
                    outlined: {
                        border: "none"
                    }
                }
            },
            MuiDialog: {
                styleOverrides: {
                    paper: {
                        borderRadius: 0,
                        boxShadow: "0px 16px 32px rgb(0 0 0 / 24%)"
                    },
                    paperWidthSm: {
                        maxWidth: 640
                    }
                }
            },
            MuiDialogContent: {
                styleOverrides: {
                    root: {
                        paddingTop: 24
                    }
                }
            },
            MuiDialogActions: {
                styleOverrides: {
                    root: {
                        justifyContent: "flex-start",
                        padding: 24
                    }
                }
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        fontSize: 12,
                        fontFamily: boldFontFamily,
                        /* grey.800 */
                        color: "#303c49",
                        marginBottom: 4
                    }
                }
            },
            MuiChip: {
                styleOverrides: {
                    root: {
                        fontFamily: boldFontFamily,
                        borderRadius: 4,
                        textTransform: "uppercase"
                    },
                    sizeSmall: {
                        fontSize: 13,
                        padding: "4p 12px",
                        height: 24
                    }
                }
            },
            MuiCheckbox: {
                defaultProps: {
                    color: "secondary"
                }
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        padding: "8px 12px",
                        "&.Mui-selected": {
                            backgroundColor: "#e40045",
                            color: "#fff",
                            "&:hover": {
                                backgroundColor: "#bb0039"
                            }
                        }
                    }
                }
            },
            MuiBreadcrumbs: {
                styleOverrides: {
                    separator: {
                        marginRight: 12,
                        marginLeft: 12
                    }
                }
            },
            MuiLink: {
                defaultProps: {
                    underline: "hover"
                },
                styleOverrides: {
                    root: {
                        color: "#e40045",
                        cursor: "pointer"
                    }
                }
            },
            MuiFab: {
                styleOverrides: {
                    root: {
                        boxShadow: "none",
                        borderRadius: 0
                    }
                }
            },
            MuiSkeleton: {
                defaultProps: {
                    animation: "pulse"
                }
            },
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        maxWidth: 400,
                        borderRadius: 2,
                        backgroundColor: "#303c49"
                    }
                }
            }
        }
    });

export default createTheme;
