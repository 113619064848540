// Copyright (C) 2022 TANNER AG

import React, { useMemo } from "react";
import useParam from "../../../../hooks/useparam";
import { usePositionCategoriesQuery } from "../../../../graphql";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import HeroIcons from "../../../../components/icons/HeroIcons";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import { CloseIcon, DataIcon } from "../../../../components/icons/MANIcons";
import useStore from "../../../../hooks/usestore";
import shallow from "zustand/shallow";

const PositionToolbarCat: React.FC = () => {
    const [{ data }] = usePositionCategoriesQuery({ variables: { id: "" } });
    const [assignMode, setAssignMode] = useStore((state) => [state.assignMode, state.setAssignMode], shallow);
    const [activeCat, setActiveCat] = useParam("cat");
    const category = useMemo(() => {
        return data?.positionCategories?.find((category) => category.id === activeCat);
    }, [activeCat, data?.positionCategories]);

    if (!category) {
        return null;
    }

    return (
        <Stack
            direction="row"
            component={Paper}
            sx={{ alignItems: "center", px: 2, py: 0.5, mb: 2, width: 1, height: 60 }}
        >
            <Stack>
                <Typography variant="caption" color="textSecondary" sx={{ mb: 0 }}>
                    Aktive Kategorie:
                </Typography>
                <Stack direction="row" spacing={1.5} sx={{ alingItems: "center" }}>
                    <HeroIcons iconName={category?.icon ?? ""} sx={{ fontSize: 30 }} />
                    <Typography variant="subtitle2" sx={{ fontSize: 18 }}>
                        {category.name}
                    </Typography>
                </Stack>
            </Stack>
            <Stack direction="row" sx={{ ml: "auto", alignItems: "center" }}>
                {!assignMode && (
                    <IconButton size="small" onClick={() => setAssignMode(true)} color="primary" sx={{ mr: 1 }}>
                        <DataIcon />
                    </IconButton>
                )}
                <IconButton size="small" onClick={() => setActiveCat()} color="primary">
                    <CloseIcon sx={{ fontSize: 16 }} />
                </IconButton>
            </Stack>
        </Stack>
    );
};

export default PositionToolbarCat;
