import * as React from "react";
import {styled} from "@mui/material/styles";
import {AutocompleteProps} from "@mui/material/Autocomplete";
import useLocale from "../../../hooks/uselocale";
import {Autocomplete, TextField} from "@mui/material";
import {CpvOptionType} from "./radarConfig.types";
import {cpvList} from "./constants";

type AutoCompleteCategoryProps = {
    selectedCategory: CpvOptionType[];
    onSelectedCategory: (value: CpvOptionType[]) => void;
};

const StyledAutoComplete = styled(Autocomplete)<AutocompleteProps<CpvOptionType, boolean, boolean, any>>(
    ({theme}) => `
    & {
        label {
            transform: none;
            position: relative;
            font-size: 20px;
            margin-bottom: 20px;
        }
        legend {
            display: none;
        }
        fieldset,
         fieldset: hover{
            border: 1px solid #abb6c3 !important;
        }
        
        &:hover fieldset {
            border: 1px solid #abb6c3;
            }
    }
`
);

export default function AutoCompleteCategory(props: AutoCompleteCategoryProps) {
    const {selectedCategory, onSelectedCategory} = props;
    const {locale} = useLocale();

    return (
        <StyledAutoComplete
            id="category"
            options={cpvList}
            multiple
            value={selectedCategory}
            getOptionLabel={(option: any) => `${option.code} - ${locale === "de" ? option.text.de : option.text.en}`}
            onChange={(event, value: unknown) => onSelectedCategory(value as CpvOptionType[])}
            renderInput={(params: any) => (
                <TextField
                    {...params}
                    label="Kategorien"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>
                    }}
                />
            )}
        />
    );
}
