// Copyright (C) 2022 TANNER AG

import React from "react";
import useHTMLParser from "../../hooks/usehtmlparser";

type Props = {
    html?: string;
    className?: string;
};

const HtmlContent: React.FC<Props> = ({ html, className = "html-content" }) => {
    const { parseHTML } = useHTMLParser();

    return <div className={className}>{parseHTML(html)}</div>;
};

export default HtmlContent;
