// Copyright (C) 2022 TANNER AG

import { useSearchParams } from "react-router-dom";
import { useMemo } from "react";

const useMultiParam = (name: string) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const values = useMemo<string[]>(() => {
        return searchParams.getAll(name);
    }, [name, searchParams]);

    const setValues = (newValues: string[] = [], override = false) => {
        const newSearchParams = new URLSearchParams(override ? undefined : searchParams);
        newSearchParams.delete("page");
        newSearchParams.delete(name);

        if (newValues.length) {
            newValues.forEach((value) => newSearchParams.append(name, value));
        }

        setSearchParams(newSearchParams);
    };

    const addValue = (value = "", override = false) => {
        if (values.includes(value)) {
            return;
        }
        setValues([...values, value], override);
    };

    const removeValue = (value = "", override = false) => {
        const index = values.indexOf(value);
        if (index === -1) {
            return;
        }
        const newValues = [...values];
        newValues.splice(index, 1);

        setValues(newValues, override);
    };

    return { values, setValues, addValue, removeValue };
};

export default useMultiParam;
