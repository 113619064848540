// Copyright (C) 2022 TANNER AG

import React from "react";
import { SvgIconProps } from "@mui/material/SvgIcon";
import { CSVIcon, FileIcon, OfficeIcon, PDFIcon, ZIPIcon } from "./MANIcons";

const FiletypeIcon: React.FC<SvgIconProps & { filetype: string }> = ({ filetype, ...props }) => {
    const test: { [key: string]: JSX.Element } = {
        pdf: <PDFIcon {...props} />,
        zip: <ZIPIcon {...props} />,
        csv: <CSVIcon {...props} />,
        pptx: <OfficeIcon {...props} />,
        xlsx: <OfficeIcon {...props} />,
        docx: <OfficeIcon {...props} />
    };

    const blub = test[filetype];
    if (blub) {
        return blub;
    }

    return <FileIcon {...props} />;
};

export default FiletypeIcon;
