import {getAlpha3Codes} from "i18n-iso-countries";
import {CountryOptionType, CpvOptionType} from "./radarConfig.types";
import cpvData from "./cpv.json";
import languages from "./languages.json";

export const countryList: CountryOptionType[] = Object.entries(getAlpha3Codes()).map((value) => ({
    alpha3: value[0],
    alpha2: value[1]
}));

export const cpvList: CpvOptionType[] = JSON.parse(JSON.stringify(cpvData)) as CpvOptionType[];

export enum categoriesTypes {
    bus = "Bus",
    marketInfo = "Market info"
}

export const languageMap: Record<string, string> = languages;

export const languageArray: Array<{label: string; value: string}> = [];
for (const [key, value] of Object.entries(languageMap)) {
    languageArray.push({label: value, value: key});
}
