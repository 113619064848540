// Copyright (C) 2022 TANNER AG

import React from "react";
import Stack from "@mui/material/Stack";
import AppMenuItem from "./AppMenuItem";

const AppMenu: React.FC = () => {
    return (
        <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 1.5, lg: 3 }} sx={{ mt: 0 }}>
            {[{ title: "TED Radar", to: "/radar" }].map((navItem, index) => (
                <AppMenuItem key={index} title={navItem.title} to={navItem.to} />
            ))}
        </Stack>
    );
};

export default AppMenu;
