import React, {useEffect, useState} from "react";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {Stack, Grid, Snackbar, Alert, AlertColor, CircularProgress, Button, Divider} from "@mui/material";
import MainWrapper from "../../../components/MainWrapper";
import AutoCompleteCategory from "./AutoCompleteCategory";
import RadarConfigToolbar from "./RadarConfigToolbar";
import RadarFilterLine from "./RadarFilterLine";
import RadarConfigNewItem from "./RadarConfigNewItem";
import AutoCompleteOrigin from "./AutoCompleteOrigin";
import CategoryTabBar from "./CategoryTabBar";
import useLocale from "../../../hooks/uselocale";
import {useAllowlistEntries} from "../hooks/useAllowlistEntries";
import {useCategoriesOrigins} from "../hooks/useCategoriesOrigins";
import useParam from "../../../hooks/useparam";
import {ReloadIcon} from "../../../components/icons/MANIcons";
import {categoriesTypes, countryList, cpvList, languageArray} from "./constants";
import {AllowListsType, AllowListEntry, CpvOptionType, CountryOptionType, languageArrayType} from "./radarConfig.types";
import {useUpdateTendersMutation} from "../../../graphql";

const RadarConfig: React.FC = () => {
    const {dateLocale} = useLocale();

    const [query, setQuery] = useParam("q");
    const [language] = useParam("lang");
    const [category, setCategory] = useParam("category", categoriesTypes.bus);

    const [isLoading, setIsLoading] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState<CpvOptionType[]>([]);
    const [selectedOrigin, setSelectedOrigin] = useState<CountryOptionType[]>([]);
    const [languageList, setLanguageList] = useState<languageArrayType>([]);
    const [whiteListRows, setWhiteListRows] = useState<AllowListsType>([]);
    const [notification, setNotification] = useState<{[key: string]: string | AlertColor} | null>(null);
    const [actualizationDate, setActualizationDate] = useState<string>();
    const [isActualizationStarted, setActualizationStarted] = useState<boolean>(false);

    const [, updateTenders] = useUpdateTendersMutation();
    const {
        data,
        allowlists,
        fetching: entriesFetching,
        setLang,
        addAllowlistEntry,
        deleteAllowlistEntry
    } = useAllowlistEntries(language);
    const {categoriesData, addCpv, addOrigin, deleteCpv, deleteOrigin, setProjectType} = useCategoriesOrigins(category);

    useEffect(() => {
        if (!categoriesData) return;
        const currentCategory = categoriesData.find((element) => element.projectType === category);
        if (currentCategory) {
            const categories = cpvList.filter((element) => currentCategory.cpv?.includes(element.code));
            setSelectedCategory(categories);
            const countries = countryList.filter((element) => currentCategory.origin?.includes(element.alpha3));
            setSelectedOrigin(countries);
        }
    }, [categoriesData, category]);

    useEffect(() => {
        setIsLoading(entriesFetching);
    }, [entriesFetching]);

    useEffect(() => {
        if (data?.allowlistEntries?.length) {
            setWhiteListRows(
                data?.allowlistEntries
                    ?.filter(({entry}) => entry.includes(query))
                    .map(({entry, lang}) => ({
                        id: crypto.randomUUID(),
                        lang,
                        value: entry
                    }))
            );
        }
    }, [data?.allowlistEntries, query]);

    useEffect(() => {
        if (!allowlists?.getAllowlists) {
            return;
        }
        const filteredLanguages = languageArray.filter(({value}) => allowlists.getAllowlists?.includes(value));
        setLanguageList(filteredLanguages);
    }, [allowlists?.getAllowlists]);

    useEffect(() => {
        setLang(language);
    }, [language, setLang]);

    const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }
        setNotification(null);
    };

    const onSelectedCategory = (value: CpvOptionType[]) => {
        const addedElements = value
            .map((item) => item.code)
            .filter((item) => !selectedCategory.map((item) => item.code).includes(item));

        addedElements.forEach((value) => addCpv(value));

        const deletedElements = selectedCategory
            .map((item) => item.code)
            .filter((item) => !value.map((item) => item.code).includes(item));
        deletedElements.forEach((value) => deleteCpv(value));

        setNotification({type: "success", message: "Änderungen erfolgreich gespeichert."});
        setSelectedCategory(value);
    };

    const onSelectedOrigin = (value: CountryOptionType[]) => {
        const addedElements = value
            .map((item) => item.alpha3)
            .filter((item) => !selectedOrigin.map((item) => item.alpha3).includes(item));

        addedElements.forEach((value) => addOrigin(value));

        const deletedElements = selectedOrigin
            .map((item) => item.alpha3)
            .filter((item) => !value.map((item) => item.alpha3).includes(item));
        deletedElements.forEach((value) => deleteOrigin(value));

        setNotification({type: "success", message: "Änderungen erfolgreich gespeichert."});
        setSelectedOrigin(value);
    };

    const handleRemoveFilterElement = (id: string) => {
        const element = whiteListRows.find((value) => value.id === id);
        if (!element) return;
        deleteAllowlistEntry({lang: element.lang, entry: element.value}).then(() =>
            setNotification({type: "success", message: "Änderungen erfolgreich gespeichert."})
        );
        setWhiteListRows(whiteListRows.filter((value) => value.id !== id));
    };

    const handleAddAllowlistEntry = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.target as HTMLFormElement;
        const formData = new FormData(form);
        const lang = formData.get("lang") as string;
        const entry = formData.get("entry") as string;
        if (lang && entry) {
            addAllowlistEntry({lang, entry}).then(() =>
                setNotification({type: "success", message: "Änderungen erfolgreich gespeichert."})
            );
            setWhiteListRows([...whiteListRows, {id: crypto.randomUUID(), lang, value: entry}]);
            const entryInput = form.elements.namedItem("entry") as HTMLInputElement;
            entryInput.value = "";
            entryInput.focus();
        }
    };

    const handleCategoryChange = (newCategory: string) => {
        setCategory(newCategory);
        setProjectType(newCategory);
    };

    const handleActualize = async () => {
        const date = new Date();
        const formattedDate = `${date.toLocaleDateString("de-DE", {
            weekday: "short",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric"
        })}`;

        await setActualizationDate(formattedDate);
        await setActualizationStarted(true);
        await updateTenders();
        await setActualizationStarted(false);
    };

    const resetQuery = () => {
        setQuery("");
    };

    return (
        <>
            {notification && (
                <Snackbar
                    anchorOrigin={{vertical: "top", horizontal: "right"}}
                    open={!!notification}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                >
                    <Alert
                        severity={notification.type as AlertColor}
                        sx={{width: "100%"}}
                        onClose={handleCloseSnackbar}
                    >
                        {notification.message}
                    </Alert>
                </Snackbar>
            )}
            {!isLoading ? (
                <>
                    <MainWrapper>
                        <Typography gutterBottom variant="h3" textTransform="uppercase" sx={{fontSize: 36}}>
                            Konfiguration
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateLocale}>
                                    <Stack>
                                        <Grid container>
                                            <Grid item xs={12} bgcolor="common.white" padding={3}>
                                                <CategoryTabBar index={category} setIndex={handleCategoryChange} />
                                                <AutoCompleteCategory
                                                    selectedCategory={selectedCategory}
                                                    onSelectedCategory={onSelectedCategory}
                                                />
                                                <AutoCompleteOrigin
                                                    selectedOrigin={selectedOrigin}
                                                    onSelectedOrigin={onSelectedOrigin}
                                                />
                                            </Grid>

                                            <Grid item xs={12} marginTop={3}>
                                                <Typography gutterBottom sx={{fontSize: 20}}>
                                                    In-list Suchbegriffe
                                                </Typography>
                                                <RadarConfigToolbar languages={languageList} />
                                            </Grid>
                                            <Grid item xs={12} bgcolor="common.white" paddingX={3}>
                                                <Typography>{whiteListRows.length} Begriffe</Typography>

                                                {!whiteListRows.length && (
                                                    <Grid marginY={3}>
                                                        <Typography component="span">
                                                            Keine Begriffe für die aktuelle Auswahl verfügbar.&nbsp;
                                                            <Typography
                                                                component="span"
                                                                onClick={resetQuery}
                                                                color="error"
                                                                sx={{cursor: "pointer"}}
                                                            >
                                                                Filter zurücksetzen
                                                            </Typography>
                                                        </Typography>
                                                    </Grid>
                                                )}

                                                {whiteListRows.map((_whiteList: AllowListEntry) => (
                                                    <RadarFilterLine
                                                        key={_whiteList.id}
                                                        item={_whiteList}
                                                        onRemoveClick={handleRemoveFilterElement}
                                                    />
                                                ))}
                                                <RadarConfigNewItem
                                                    languages={languageList}
                                                    onSubmit={handleAddAllowlistEntry}
                                                />
                                            </Grid>
                                            <Grid item width="100%" marginY={3}>
                                                <Divider />
                                            </Grid>

                                            <Grid item bgcolor="common.white" width="100%" padding={3}>
                                                <Typography variant="h3">Ausschreibungen aktualisieren</Typography>
                                                <Typography variant="subtitle1" fontSize={14}>
                                                    Aufschreibungen auf Basis der aktuellen Konfiguration neu laden.
                                                    Dies kann 30 - 60 Minuten dauern.
                                                </Typography>
                                                <Button
                                                    startIcon={<ReloadIcon />}
                                                    variant="outlined"
                                                    onClick={handleActualize}
                                                    disabled={isActualizationStarted}
                                                >
                                                    Ausschreibungen aktualisieren
                                                </Button>

                                                {actualizationDate && (
                                                    <Typography variant="subtitle1" fontSize={14}>
                                                        `Letzte Aktualisierung: gestartet am ${actualizationDate}`
                                                    </Typography>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    </MainWrapper>
                </>
            ) : (
                <Container
                    sx={{
                        display: "flex",
                        width: "100vw",
                        height: "100vh",
                        justifyContent: "center",
                        paddingTop: "20%"
                    }}
                >
                    <CircularProgress />
                </Container>
            )}
        </>
    );
};

export default RadarConfig;
