// Copyright (C) 2021 TANNER AG

import { useCallback } from "react";
import useLocale from "./uselocale";
import fnsFormat from "date-fns/format";

const useDatetime = () => {
    const { dateLocale } = useLocale();

    const fDate = useCallback(
        (date?: Date | string, format = "MMM dd, yyyy"): string => {
            try {
                if (!date) {
                    return "";
                }

                if (typeof date === "string") {
                    return fnsFormat(new Date(date), format, { locale: dateLocale });
                }

                return fnsFormat(date, format, { locale: dateLocale });
            } catch (err) {
                console.log(err);
                return "";
            }
        },
        [dateLocale]
    );

    return { fDate };
};

export default useDatetime;
