// Copyright (C) 2022 TANNER AG

import React from "react";
import useMultiParam from "../hooks/usemultiparam";
import Chip from "@mui/material/Chip";

type Props = {
    label: string;
    field: string;
};

const FilterActiveParam: React.FC<Props> = ({ label, field }) => {
    const { values, removeValue } = useMultiParam(field);
    return (
        <>
            {values.map((value) => (
                <Chip key={value} label={`${label}: ${value}`} onDelete={() => removeValue(value)} />
            ))}
        </>
    );
};

export default FilterActiveParam;
