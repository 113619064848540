// Copyright (C) 2022 TANNER AG

import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

type Props = {
    label?: string;
    children?: React.ReactNode;
};

const PositionDetailsGroup: React.FC<Props> = ({ label, children }) => {
    return (
        <Box sx={{ mb: 2 }}>
            {label && (
                <Typography variant="subtitle2" sx={{ fontSize: 16 }} gutterBottom>
                    {label}
                </Typography>
            )}
            {children}
        </Box>
    );
};

export default PositionDetailsGroup;
