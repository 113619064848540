// Copyright (C) 2022 TANNER AG

import React from "react";
import FormSelect from "../../../../components/inputs/FormSelect";
import { useSearchParams } from "react-router-dom";

type Props = {
    types: string[];
};

const PositionFilterType: React.FC<Props> = ({ types }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const value = searchParams.get("type") ?? "";

    const handleChange = (value: string) => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.delete("page");
        newSearchParams.set("type", value);
        setSearchParams(newSearchParams);
    };

    return (
        <FormSelect
            id="type"
            label="Typ"
            options={[{ label: "Alle", value: "" }, ...types.map((type) => ({ label: type, value: type }))]}
            SelectProps={{ value: value || "", onChange: (event) => handleChange(event.currentTarget.value) }}
        />
    );
};

export default PositionFilterType;
