// Copyright (C) 2020 TANNER AG

import React, { MouseEvent } from "react";
import { styled } from "@mui/material";
import useDocStore from "../../store/usedocstore";

type Props = {
    src: string;
    [key: string]: string;
};

const Image: React.FC<Props> = ({ src, alt, ...others }) => {
    const setDrawer = useDocStore((state) => state.setActiveImage);
    const setActiveItemData = useDocStore((state) => state.setActiveItemData);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        const graphicId = event.currentTarget.parentElement?.getAttribute("id");
        if (graphicId) {
            setActiveItemData(graphicId, "graphic");
        } else {
            setActiveItemData();
            setDrawer(src);
        }
    };

    return (
        <Root className="img-wrapper" onClick={handleClick}>
            <img {...others} alt={alt} loading="lazy" decoding="async" src={src} />
        </Root>
    );
};

const Root = styled("div")(({ theme }) => ({
    position: "relative",
    display: "inline-flex"
}));

export default Image;
