// Copyright (C) 2022 TANNER AG

import { useTheme, Breakpoint } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const useBreakpoint = (key: Breakpoint) => {
    const theme = useTheme();
    const up = useMediaQuery(theme.breakpoints.up(key));
    const down = useMediaQuery(theme.breakpoints.down(key));

    return {
        up,
        down
    };
};

export default useBreakpoint;
