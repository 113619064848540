// Copyright (C) 2022 TANNER AG

import React from "react";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

type Props = {
    title: string;
    to: string;
};

const AppMenuItem: React.FC<Props> = ({ title, to }) => {
    return (
        <LinkItem
            // @ts-ignore
            component={Link}
            to={to}
            variant="subtitle2"
            textTransform="uppercase"
        >
            {title}
        </LinkItem>
    );
};

export const LinkItem = styled(Typography)(({ theme }) => ({
    cursor: "pointer",
    fontSize: 14,
    textDecoration: "none",
    color: theme.palette.text.primary,
    "&:hover": { color: theme.palette.secondary.main },
    [theme.breakpoints.up("lg")]: {
        fontSize: 16
    }
}));

export default AppMenuItem;
