// Copyright (C) 2020 TANNER AG

import React from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { SxProps } from "@mui/material";

type Props = {
    title?: string;
    message?: string;
    cause?: string;
    sx?: SxProps;
};

const ErrorAlert: React.FC<Props> = ({ title = "", message = "", cause = "", sx }) => {
    return (
        <Alert severity="error" sx={sx}>
            <AlertTitle sx={{ m: 0 }}>{title?.toString() || ""}</AlertTitle>
            {message?.toString() || ""}
            {cause || ""}
        </Alert>
    );
};

export default ErrorAlert;
