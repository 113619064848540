// Copyright (C) 2022 TANNER AG

import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ListItemWrapper } from "../../../../../components/listitem/ListItemRegular";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { ArrowRightIcon, ChevronRightIcon } from "../../../../../components/icons/MANIcons";
import Button from "@mui/material/Button";
import useTranslation from "../../../../../hooks/usetranslation";
import { Keyword, Position } from "../../../../../graphql";
import useParam from "../../../../../hooks/useparam";
import Chip from "@mui/material/Chip";
import useHTMLParser from "../../../../../hooks/usehtmlparser";
import PositionListItemAdd from "./PositionListItemAdd";

type Props = {
    position: Pick<Position, "id" | "description" | "pos" | "type" | "levels" | "references">;
    subItems?: Pick<Position, "id" | "description">[];
    keywords?: Pick<Keyword, "key" | "text">[];
};

const PositionListItem: React.FC<Props> = ({ position, keywords }) => {
    const { parseHTML } = useHTMLParser();
    const { t } = useTranslation();
    const [, setPos] = useParam("pos");
    const [, setQuery] = useParam("q");

    return (
        <ListItemWrapper>
            <Stack sx={{ p: 1, pt: 1.5, pl: 1.5, flexGrow: 1 }}>
                <Stack sx={{ mr: 6 }}>
                    <Breadcrumbs
                        separator={<ChevronRightIcon sx={{ fontSize: 10 }} />}
                        sx={{ "& .MuiBreadcrumbs-separator": { mx: 1, mt: 0.25 } }}
                    >
                        {position.levels
                            ?.filter((level) => !level.startsWith("-"))
                            ?.map((level, key) => (
                                <Typography
                                    key={key}
                                    variant="h4"
                                    sx={{ lineHeight: "28px", fontSize: 16, color: "text.secondary" }}
                                >
                                    {level}
                                </Typography>
                            ))}
                        <Typography
                            variant="h4"
                            sx={{ lineHeight: "28px", fontSize: 16, color: "text.secondary" }}
                            className="heading"
                        >
                            {position.pos}
                        </Typography>
                    </Breadcrumbs>
                    <Typography variant="body2" sx={{ pr: 2 }} component="div" className="html-content">
                        {parseHTML(position.description)}
                    </Typography>
                </Stack>
                <Stack direction="row" sx={{ justifyContent: "space-between", alignItems: "center", mt: 0.5 }}>
                    <Stack spacing={0.5} direction="row">
                        {keywords?.map((keyword, index) => (
                            <Chip
                                key={`${index}-${keyword}`}
                                size="small"
                                label={keyword.text}
                                color="primary"
                                variant="outlined"
                                sx={{ ".MuiChip-label": { textTransform: "capitalize" } }}
                                onClick={() => setQuery(keyword.text, true)}
                            />
                        ))}
                    </Stack>
                    <div>
                        <PositionListItemAdd id={position.id} />
                        <Button onClick={() => setPos(position.id)} startIcon={<ArrowRightIcon color="secondary" />}>
                            {t("show_details")}
                        </Button>
                    </div>
                </Stack>
            </Stack>
            <Stack direction="row" spacing={1} sx={{ position: "absolute", top: { xs: 4, sm: 12 }, right: 18 }}>
                <Typography variant="subtitle2">{position.type}</Typography>
            </Stack>
        </ListItemWrapper>
    );
};

export default PositionListItem;
