// Copyright (C) 2022 TANNER AG

import React from "react";
import Stack from "@mui/material/Stack";
import PositionListItem from "./listitem/PositionListItem";
import PositionToolbar from "./PositionToolbar";
import Pagination from "./Pagination";
import usePositions from "../../hooks/usepositions";
import PositionListNoResult from "./PositionListNoResult";

type Props = {
    id: string;
};

const PositionList: React.FC<Props> = ({ id }) => {
    const { items, total, levels, types, fetching } = usePositions(id);

    return (
        <>
            <PositionToolbar levels={levels} types={types} total={total} />
            {Boolean(!fetching && !items.length) && <PositionListNoResult />}
            <Stack spacing={2}>
                {items.map((position) => (
                    <PositionListItem
                        key={position.id}
                        position={position}
                        subItems={position.subitems}
                        keywords={position.keywords}
                    />
                ))}
            </Stack>
            <Pagination total={total} />
        </>
    );
};

export default PositionList;
