// Copyright (C) 2022 TANNER AG

import React from "react";
import Typography from "@mui/material/Typography";
import { InfoItem } from "../../../graphql";
import Box from "@mui/material/Box";
import ResultListWrapper from "./ResultListItemWrapper";
import ResultListItemAction from "./ResultListItemAction";

type Props = {
    item: InfoItem;
};

const ResultListItem: React.FC<Props> = ({ item }) => {
    return (
        <ResultListWrapper actions={<ResultListItemAction item={item} />}>
            <Typography variant="h4" sx={{ lineHeight: "28px", fontSize: 18 }} className="heading">
                {item.title}
            </Typography>
            <Typography variant="h5" color="textSecondary" sx={{ fontSize: 16 }} gutterBottom>
                {item?.pdf ?? item.target?.docid}
            </Typography>
            <Box>
                {item?.matches?.map((match, index) => (
                    <Typography
                        key={index}
                        component="span"
                        variant="body2"
                        dangerouslySetInnerHTML={{
                            __html: `${match.text}${Boolean(index < (item?.matches?.length ?? 0) - 1) ? "..." : ""}`
                        }}
                    />
                ))}
            </Box>
        </ResultListWrapper>
    );
};

export default ResultListItem;
