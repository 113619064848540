// Copyright (C) 2022 TANNER AG

import { usePositionsQuery } from "../../../graphql";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import useStore from "../../../hooks/usestore";

const RESULTS_PER_PAGE = 10;

const usePositions = (id: string) => {
    const assignMode = useStore((state) => state.assignMode);
    const [searchParams] = useSearchParams();
    const query = searchParams.get("q") ?? "";
    const page = searchParams.get("page") ?? "1";
    const level = searchParams.get("level") ?? "";
    const cat = searchParams.get("cat") ?? "";
    const type = searchParams.get("type") ?? "";

    const [start, limit] = useMemo(() => {
        return [(Number(page) - 1) * RESULTS_PER_PAGE, RESULTS_PER_PAGE];
    }, [page]);

    const [{ data, fetching, error }] = usePositionsQuery({
        variables: { where: { id, query, level, cat, assignMode, type }, pagination: { start, limit } }
    });

    return {
        total: data?.positions?.total ?? 0,
        items: data?.positions?.items ?? [],
        levels: data?.positions?.filter?.levels ?? [],
        types: data?.positions?.filter?.types ?? [],
        fetching,
        error
    };
};

export default usePositions;
